import { AnyAction } from "redux";
import { ILandingState } from "../models";
import { GET_LANDING } from "../actions";

const initialState = {
  categories: [],
  landingPage: [],
  specialOffer: [],
  subCategory: [],
  marquee: [],
  middleSection: [],
  isFetching: false,
};

export function landing(
  state: ILandingState = initialState,
  action: AnyAction
): ILandingState {
  switch (action.type) {
    case GET_LANDING:
      let list = action.data.data;
      state = list;

      return { ...state, isFetching: false };
    default:
      return state;
  }
}
