import React, {ChangeEvent} from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  OrderSizeQuantity,
  OrderCustomizedProduct,
  IAppState,
  IOrderState,
} from "../../models";

import { orderClient } from "../../ApiClients/OrderClient";
import styled from "styled-components";
import { TextBeta, TextTheta, TinyText } from "@happeouikit/typography";
import {
  marginL,
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
} from "../../constants";

import OrderInfo from "./OrderInfo";

import {
  LeftSection,
  FormGroup,
  RightSection,
  ModalWrapper,
  ModalContainer,
} from "./styled.component";

import { IOneProductV2 } from "../../models/ApiResponses/IGetProductResponseV2";
import { toast } from "@happeouikit/toast";
import { Modal, ConfirmModal } from "@happeouikit/modals";
import { Loader } from "@happeouikit/loaders";

import PaymentForm from "../Payment/Payment.form";
import PaymentDetails from "../Payment/Payment.details";

import { paymentClient } from "../../ApiClients/PaymentClient";

import { formatDate } from "../../Utils/common";

interface OrderContainerProps {
  order: IOrderState;
  getOrder(): void;
}

interface OrderContainerState {
  // items: DummyOrderItem;
  orderData: any;
  openAccordionIndices: Record<number, boolean>;
  selectedItem: any;
  isOpenConfirmModal: boolean;
  screenLoader: boolean;
  isPaymentModalOpen: boolean;
  isPaymentDetailsModalOpen: boolean;
}

export class OrderContainerComponent extends React.Component<
  OrderContainerProps,
  OrderContainerState
> {
  constructor(props: OrderContainerProps) {
    super(props);
    this.state = {
      orderData: [],
      // items: DummyOrderItem.data.customizedProducts,
      openAccordionIndices: {},
      selectedItem: false,
      isOpenConfirmModal: false,
      screenLoader: false,
      isPaymentModalOpen: false,
      isPaymentDetailsModalOpen: false
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  orderList = () => {
    orderClient.getOrder1().then((orderData) => {
      console.log(orderData, "orderDataorderDataorderData");
      this.setState({
        orderData: orderData ?? [],
        screenLoader: false
      });
    });
  }

  componentDidMount(): void {
    this.setState({ screenLoader: true });
    this.orderList();
    // this.props.getOrder();
  }

  toggleAccordion(index: number) {
    const { openAccordionIndices } = this.state;
    if (openAccordionIndices[index]) {
      openAccordionIndices[index] = false;
    } else {
      openAccordionIndices[index] = true;
    }

    this.setState({ openAccordionIndices: { ...openAccordionIndices } });
  }

  private getPrice(item: OrderSizeQuantity[]): string {
    console.log("🚀 ~ file: OrderContainer.tsx:69 ~ getPrice ~ item:", item);

    return (Math.random() * 10).toFixed();
  }

  cancelOrderItemConfirm = (cartItem: any, index: any) => {
    this.setState({
      selectedItem: cartItem,
      isOpenConfirmModal: true,
    });
    this.toggleAccordion(index);
  };

  closeConfirmModal = () => {
    this.setState({
      selectedItem: false,
      isOpenConfirmModal: false,
    });
  };

  cancelOrderItem = (orderItem: any) => {
    if (!orderItem) {
      toast.error({ message: "Invalid cart item." });
      return false;
    }

    const cartData = {
      _id: orderItem._id,
    };
    orderClient.cancelOrder(cartData).then((re) => {
      if (!re.error) {
        toast.success({ message: "Order Item has been cancelled!" });
        this.closeConfirmModal();
        /* call order list api */
        this.orderList();
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };

  paymentOrderItem = (orderItem: any, index: any) => {
    //window.open("/payment", "__blank");
    if(orderItem.status == "pending"){
      this.setState({
        selectedItem: orderItem,
        isPaymentModalOpen: true,
        isPaymentDetailsModalOpen: false
      });
    } else {
      paymentClient.paymentDetails(orderItem._id).then((payData) => {
          console.log(payData);
          this.setState({
            selectedItem: orderItem,
            isPaymentModalOpen: payData.data.length == 0?true:false,
            isPaymentDetailsModalOpen: payData.data.length == 0?false:true
          });
      });
    }
    
    this.toggleAccordion(index)
    return false;
  };

  paymentDetails = (orderItem: any, index: any) => {
    this.setState({
      selectedItem: orderItem,
      isPaymentModalOpen: false,
      isPaymentDetailsModalOpen: true
    });
    this.toggleAccordion(index)
    return false;
  }

  closePaymentModal = () => {
    this.setState({
      selectedItem: false,
      isPaymentModalOpen: false,
      isPaymentDetailsModalOpen: false
    });
  }

  formatPrice = (price: any) => {
    return parseFloat(price).toFixed(2);
  }

  render(): React.ReactNode {
    const {
      openAccordionIndices,
      orderData,
      selectedItem,
      isOpenConfirmModal,
      screenLoader,
      isPaymentModalOpen,
      isPaymentDetailsModalOpen,
    } = this.state;

    return (
      <>
        <MainContainer>
          <Wrapper>
            <SettingHeader>
              <TextBeta className="bold">My Orders</TextBeta>
              <TotalPrice>
                {/* <TextTheta>Estimate Total:</TextTheta> */}
              </TotalPrice>
            </SettingHeader>
          </Wrapper>
        </MainContainer>
        <Wrapper>
          {screenLoader ? (<LoaderOverlay>
                <LoaderContainer>
                  <Loader size="large"></Loader>
                </LoaderContainer>
              </LoaderOverlay>) : <SecondaryContainer>
            {orderData.length > 0 ? (
              orderData.map((item: any, index: number) => {
                console.log("item.productId : ", item);
                const isOpen = !!openAccordionIndices[index];
                return (
                  <OrderItemContianer key={index} style={{ margin: "10px 0" }}>
                    <OrderItemHeader
                      isOpen={isOpen}
                      onClick={() => this.toggleAccordion(index)}
                    >
                      <OrderItemHeaderTitle>
                        <span>{item.orderId}</span>
                        <span> | </span>
                        <span>Total Item(s) : </span>
                        <span>
                          {item.productList && item.productList.length}
                        </span>
                        <span> | </span>
                        <span>Date : </span>
                        <span>
                          { formatDate(item.placedAt) }
                        </span>
                      </OrderItemHeaderTitle>
                      <OrderItemHeaderPrice>
                        {item.orderCancelled ? (
                          <>
                            <span>
                              <ButtonBita
                                style={{
                                  height: "fit-content",
                                  margin: "3.3%",
                                  marginRight: "15px",
                                  width: "max-content",
                                }}
                                onClick={() => {}}
                              >
                                Cancelled
                              </ButtonBita>
                            </span>
                          </>
                        ) : (
                          <>
                            {item.orderAccepted && item.status != "payment completed" && (
                              <span>
                                <ButtonAlpha
                                  style={{
                                    height: "fit-content",
                                    margin: "3.3%",
                                    marginRight: "15px",
                                    width: "max-content",
                                  }}
                                  onClick={() =>
                                    this.cancelOrderItemConfirm(item, index)
                                  }
                                >
                                  Cancel Order
                                </ButtonAlpha>
                              </span>
                            )}
                            {item.orderAccepted && (
                              <span>
                                <ButtonAlpha
                                  style={{
                                    height: "fit-content",
                                    margin: "3.3%",
                                    marginRight: "15px",
                                    marginTop: "5.3%",
                                    width: "max-content",
                                  }}
                                  onClick={() =>
                                    this.paymentOrderItem(item, index)
                                  }
                                >
                                  {item.status == "pending"?"Pay Now":"Payment Details"}
                                </ButtonAlpha>
                              </span>
                            )}
                          </>
                        )}
                        <span>Status : {item.status}</span>
                        <span> | </span>
                        <span>
                          Order Estimate: {item.currency} {typeof item.additionalData !== "undefined" && typeof item.additionalData.netOrderAmount !== "undefined"?this.formatPrice(item.additionalData.netOrderAmount):this.formatPrice(item.totalAmount)}
                        </span>
                      </OrderItemHeaderPrice>
                    </OrderItemHeader>
                    {isOpen &&
                      item.productList.length > 0 &&
                      item.productList.map((ordItem: any) => (
                        <OrderInfo orderDataItem={ordItem} />
                      ))}
                  </OrderItemContianer>
                );
              })
            ) : (
              <h2>No Records</h2>
            )}
            <PaymentForm 
              title="Payment"
              isOpen={isPaymentModalOpen}
              close = {() => this.closePaymentModal()}
              onDone={this.orderList}
              selectedOrder={selectedItem}
            />
            <PaymentDetails
              title="Payment Details"
              isOpen={isPaymentDetailsModalOpen}
              close = {() => this.closePaymentModal()}
              onDone={this.orderList}
              selectedOrder={selectedItem}
            />
            <ConfirmModal
              isOpen={isOpenConfirmModal}
              close={this.closeConfirmModal}
              header={"Cancel order"}
              bodyText={
                "Do you really want to delete? This action can't be reverted."
              }
              okCb={() => this.cancelOrderItem(selectedItem)}
              okText={"Accept"}
              state={"warn"}
            />
          </SecondaryContainer>}
        </Wrapper>
      </>
    );
  }
}

export const MainContainer = styled.div`
  /* min-height: 500px; */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  display: flex;
  flex-direction: column;
`;

const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const SecondaryContainer = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
`;

const Wrapper = styled.div``;

const TotalPrice = styled.div`
  p {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: bold;
  }
`;

const OrderItemContianer = styled.div``;

const OrderItemHeader = styled.div<{ isOpen: boolean }>`
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (!props.isOpen ? "#ECECEC" : "#8585855e")};
  display: flex;
  padding: 8px 12px;
  border-radius: 5px;
  min-height: 60px;
  cursor: pointer;
`;

const OrderItemHeaderTitle = styled.div`
  width: 40%;
`;

const OrderItemHeaderPrice = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
`;

const ModifierContainer = styled.div`
  margin-top: ${marginM};
`;

const ModifierBreakDownTable = styled.table`
  width: 100%;
`;

const ButtonAlpha = styled.button`
  all: unset;
  text-align: left;
  font: normal normal 600 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #ffffff;
  background: #142f8f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
`;

const ButtonBita = styled.button`
  all: unset;
  text-align: left;
  font: normal normal 600 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #ffffff;
  background: brown 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: calc(50vh - 150px);
  right: calc(50vw - 10px);
  z-index: 999999;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #8080806b;
  z-index: 999999;
`;

const mapStateToProps = (state: IAppState) => {
  return {
    order: state.order,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrder: () => orderClient.getOrder(dispatch),
  };
};

export const OrderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderContainerComponent);
