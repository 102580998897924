import React, {useState,useEffect,ChangeEvent} from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    LeftSection,
    FormGroup,
    FormContainer,
    RightSection,
    ModalWrapper,
    ModalContainer,
  } from "./styled.component";


import { Modal } from "@happeouikit/modals";

import { paymentClient } from "../../ApiClients/PaymentClient";

import Table, { Center } from "../../components/Table";

type PaymentDetailsProps = {
    title: string;
    isOpen: boolean;
    close(): void;
    onDone(): void;
    disabled?: boolean;
    selectedOrder?: any;
};

const PaymentDetails = ({title, isOpen, selectedOrder, disabled, onDone, close} : PaymentDetailsProps) => {
    const [paymentData, setPaymentData] = useState<any>({});

    useEffect(() => {
        if (selectedOrder) {
            paymentClient.paymentDetails(selectedOrder._id).then((payData: any) => {
                console.log(payData.data[0]);
                //setPaymentData(payData?JSON.stringify(payData, null, "\t"):"");
                setPaymentData(payData.data.length > 0?payData.data[0]:"");
            });
        }
    }, [selectedOrder]);

    const formatPrice = (price:any) => {
      return parseFloat(price).toFixed(2);
    }

    return (<>
        <Modal
            isOpen={isOpen}
            close={close}
            headerText={<span style={{fontFamily: "helvetica sans-serif !important;", fontSize: "x-large"}}>{title}</span> || ""}
            width={"984px"}
            disabled={disabled}
            okText={"Ok"}
            okCallback={() => {
                close();
            }}
            cancelText={"Cancel"}
            overlayStyle={{ zIndex: "1061", fontFamily: "helvetica sans-serif !important;" }}
          >
            <ModalWrapper>
              
                {typeof paymentData.orderDetails !== "undefined" ? <>
                  <ModalContainer> 
                    <LeftSection>
                      <table>
                        <tr>
                          <th colSpan={2} style={{"textAlign":"left"}}><u>Order Details</u></th>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Order Id :</td>
                          <td style={{"textAlign":"left"}}> {paymentData?.orderId}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Order Status :</td>
                          <td style={{"textAlign":"left"}}> {paymentData?.status}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Order Amount :</td>
                          <td style={{"textAlign":"left"}}> {paymentData?.currency} {formatPrice(paymentData?.totalAmount)}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Invoice Number :</td>
                          <td style={{"textAlign":"left"}}> {paymentData?.orderDetails.invoiceNumber}</td>
                        </tr>
                        <tr><td style={{marginBottom:"10px"}}>&nbsp;</td></tr>
                        <tr>
                          <th colSpan={2} style={{"textAlign":"left"}}><u>Transaction Details</u></th>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Transaction Id :</td>
                          <td style={{"textAlign":"left"}}> {paymentData?.transactionId}</td>
                        </tr>
                      </table>
                      
                      {/* <FormGroup><u>Order Details</u></FormGroup>
                      <FormGroup><span style={{"textAlign":"right"}}>Order Id </span>:<span style={{"textAlign":"left"}}>{paymentData?.orderId}</span></FormGroup>
                      <FormGroup><span  >Order Status</span>:<span style={{"textAlign":"left"}}>{paymentData?.status}</span></FormGroup>
                      <FormGroup><span style={{"textAlign":"right"}}>Order Amount</span>:<span style={{"textAlign":"left"}}>{paymentData?.currency} {paymentData?.totalAmount}</span></FormGroup>
                      <FormGroup>invoiceNumber : {paymentData?.orderDetails.invoiceNumber}</FormGroup> */}
                      {/* <FormGroup><u>Transaction Details</u></FormGroup>
                      <FormGroup>Transaction Id : {paymentData?.transactionId}</FormGroup>
                      {paymentData.orderDetails.cardDetails && <><FormGroup><u>Card Details</u></FormGroup>
                      <FormGroup>Number : {paymentData?.orderDetails.cardDetails.cardNumber}</FormGroup>
                      <FormGroup>Expiry : {paymentData?.orderDetails.cardDetails.expiryDate}</FormGroup></>} */}
                    </LeftSection>
                    
                    <RightSection>
                      <table>
                        <tr>
                          <th colSpan={2} style={{"textAlign":"left"}}>{paymentData?.userName}</th>
                        </tr>
                        <tr>
                          <th colSpan={2} style={{"textAlign":"left"}}>{paymentData?.userEmail}</th>
                        </tr>
                        <tr><td style={{marginBottom:"10px"}}>&nbsp;</td></tr>
                        {paymentData.orderDetails.billingAddress && <><tr>
                          <th colSpan={2} style={{"textAlign":"left"}}><u>Billing Details</u></th>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Name :</td>
                          <td style={{"textAlign":"left"}}> {paymentData.orderDetails.billingAddress.firstName}&nbsp;{paymentData.orderDetails.billingAddress.lastName}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Phone :</td>
                          <td style={{"textAlign":"left"}}> {paymentData.orderDetails.billingAddress.phoneNo}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Address :</td>
                          <td style={{"textAlign":"left"}}> {paymentData.orderDetails.billingAddress.address},&nbsp;{paymentData.orderDetails.billingAddress.city}&nbsp;{paymentData.orderDetails.billingAddress.state}&nbsp;{paymentData.orderDetails.billingAddress.zipcode}</td>
                        </tr>
                        </>}
                        <tr><td style={{marginBottom:"10px"}}>&nbsp;</td></tr>
                        {paymentData.orderDetails.shippingAddress && <><tr>
                          <th colSpan={2} style={{"textAlign":"left"}}><u>Shipping Details</u></th>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Name :</td>
                          <td style={{"textAlign":"left"}}> {paymentData.orderDetails.shippingAddress.firstName}&nbsp;{paymentData.orderDetails.shippingAddress.lastName}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Phone :</td>
                          <td style={{"textAlign":"left"}}> {paymentData.orderDetails.shippingAddress.phoneNo}</td>
                        </tr>
                        <tr>
                          <td style={{"textAlign":"right"}}>Address :</td>
                          <td style={{"textAlign":"left"}}> {paymentData.orderDetails.shippingAddress.address},&nbsp;{paymentData.orderDetails.shippingAddress.city}&nbsp;{paymentData.orderDetails.shippingAddress.state}&nbsp;{paymentData.orderDetails.shippingAddress.zipcode}</td>
                        </tr>
                        </>}
                      </table>
                      {/* <FormGroup>{paymentData?.userName}</FormGroup>
                      <FormGroup>{paymentData?.userEmail}</FormGroup>
                      {paymentData.orderDetails.billingAddress && <><FormGroup><u>Billing Details</u></FormGroup>
                      <FormGroup>Name : {paymentData.orderDetails.billingAddress.firstName}&nbsp;{paymentData.orderDetails.billingAddress.lastName}</FormGroup>
                      <FormGroup>Phone : {paymentData.orderDetails.billingAddress.phoneNo}</FormGroup>
                      <FormGroup>Address : {paymentData.orderDetails.billingAddress.address},&nbsp;{paymentData.orderDetails.billingAddress.city}&nbsp;{paymentData.orderDetails.billingAddress.state}&nbsp;{paymentData.orderDetails.billingAddress.zipcode}</FormGroup></>}
                  
                      {paymentData.orderDetails.shippingAddress && <><FormGroup><u>Shipping Details</u></FormGroup>
                      <FormGroup>Name : {paymentData.orderDetails.shippingAddress.firstName}&nbsp;{paymentData.orderDetails.shippingAddress.lastName}</FormGroup>
                      <FormGroup>Phone : {paymentData.orderDetails.shippingAddress.phoneNo}</FormGroup>
                      <FormGroup>Address : {paymentData.orderDetails.shippingAddress.address},&nbsp;{paymentData.orderDetails.shippingAddress.city}&nbsp;{paymentData.orderDetails.shippingAddress.state}&nbsp;{paymentData.orderDetails.shippingAddress.zipcode}</FormGroup></>} */}
                    </RightSection>
                  </ModalContainer>
                  <ModalContainer>
                    <section style={{}}>
                      <FormGroup>
                        {paymentData.orderDetails.products && paymentData.orderDetails.products.length > 0 && (
                          <>
                            <Center V={true} H={true}>
                              <Table data={paymentData.orderDetails.products.map(
                                (billInfo: any, i:number) => ({
                                  "SL.no.": (i+1),
                                  "Item Name": billInfo.name,
                                  "Description": billInfo.description,
                                  "Quantity": billInfo.quantity,
                                })
                              )} />
                            </Center>
                          </>
                        )}
                      </FormGroup>
                    </section>
                  </ModalContainer>
                </>:<h2>No order details found.</h2>}
              
            </ModalWrapper>
        </Modal></>);
}

export default PaymentDetails;