import React from "react";
import { Title } from "../../components/Title";

import {
  Container,
  StyledHeader,
  StyledSpan,
  StyledP,
  StyledTable,
  Styledtd,
  StyledTdTitle,
  StyledLink,
  StyledPBlue,
  StyldSpanBlack,
  StyledA,
} from "./styled.components";

interface CareerPageProps {}

interface CareerPageState {}

export class CareerPage extends React.Component<
  CareerPageProps,
  CareerPageState
> {
  constructor(props: CareerPageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Title>Job Opening</Title>
        <Container>
          <StyledHeader>
            PART-TIME INVENTORY AND PRODUCTION ASSISTANT{" "}
          </StyledHeader>
          <StyledP>
            We are a internet based embroidery company that produces clothing
            for fraternities and sororities. The company has been established
            since 2001 and we have an opening for a{" "}
            <StyledSpan> Permanent Part-Time </StyledSpan>(P/T) position to
            assist in checking the quality of the merchandise, putting away and
            managing inventory, cleaning the shop, and office work. A friendly,
            courteous, positive attitude is a must. This is a team environment.{" "}
          </StyledP>
          <p>
            A friendly, courteous, positive attitude is a must. This is a team
            environment.
          </p>
          <StyledPBlue>
            This position is great for a college student, stay-at-home mom or
            someone looking for a part-time job.
          </StyledPBlue>
          <StyledSpan>
            Only local applicants need apply. Part-Time ONLY<br></br>
          </StyledSpan>
          <StyldSpanBlack>(Fremont, Union City, Newark)</StyldSpanBlack>
          <br></br>
          <StyledSpan>
            Non Smoking Environment <br></br>(This is NOT a warehouse position,
            we do not use forklifts.)
          </StyledSpan>
          <StyledTable>
            <tbody>
              <tr>
                <StyledTdTitle>Location:</StyledTdTitle>
                <Styledtd>Fremont, CA</Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Experience:</StyledTdTitle>
                <Styledtd>
                  Entry Level (we will train for this position)
                </Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Hours:</StyledTdTitle>
                <Styledtd>
                  Monday to Friday - 9:00 am - 1:00 pm (Flexible) or 3:30 pm to
                  6:30 pm - Busy Season (Nov. to Dec. and Mar.to June) - more
                  hours and some weekends
                </Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Pay</StyledTdTitle>
                <Styledtd>$18.00 per hour</Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Responsibilities:</StyledTdTitle>
                <Styledtd>
                  <ul>
                    <li>Create labels/inventory locations for new product </li>
                    <li>
                      Ensure all incoming items are received from QA/Receiving,
                      checked for accuracy, properly labeled and put away to
                      proper location
                    </li>
                    <li>
                      {" "}
                      Return items to inventory when pulled for orders in error,
                      overstock or customer returns (RA)
                    </li>
                    <li>
                      Maintain clean, neat and orderly work space and Stock Room
                    </li>
                    <li>
                      Remove cardboard/trash to recycling at the end of each
                      shift
                    </li>
                    <li>
                      Assist in maintaining accurate inventory numbers •
                      Understanding of on-hand, committed, available quantities,
                      build and adjust processes
                    </li>
                    <li> Assist Order Fulfillment and Shipping as needed </li>
                    <li>
                      {" "}
                      Assist in performing accurate and timely physical
                      bi-annual inventory counts • Adhere to production,
                      productivity, quality, and customer-service standards{" "}
                    </li>
                    <li>
                      {" "}
                      Light Office work -- filing, data entry • Perform special
                      projects and other duties as assigned
                    </li>
                  </ul>
                </Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Qualifications:</StyledTdTitle>
                <Styledtd>
                  <ul>
                    <li>
                      Able to work independently and with minimal supervision
                    </li>
                    <li> Dependable and prompt</li>
                    <li>
                      High level of attention to detail and strong focus on
                      accuracy in work
                    </li>
                    <li>
                      {" "}
                      Fine motor skills (cutting fine thread and using scissors)
                    </li>
                    <li> Able to safely consistently lift up to 25 lbs.</li>
                    <li> Bending and stooping occasionally</li>
                    <li>
                      Frequent standing, walking, and reaching with hands and
                      arms
                    </li>
                    <li> Good math skills required.</li>
                    <li> Must be able to read and understand English</li>
                    <li>
                      Some degree of flexibility; available to work overtime
                      when needed
                    </li>
                    <li> High School diploma or equivalent required</li>
                    <li>
                      Prior experience working in stockroom or similar is a plus
                    </li>
                    <li> Must be a U.S. Citizen</li>
                  </ul>
                </Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Additional Skills:</StyledTdTitle>
                <Styledtd>
                  <ul>
                    <li>
                      Know of Tajima embroidery machine - a plus (will train)
                    </li>
                    <li>Sewing and tailoring ability - a plus</li>
                  </ul>
                </Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>How to Apply:</StyledTdTitle>
                <Styledtd>
                  Email Resume in the following formats ( .doc(s), .pdf, .txt)
                  <br></br>
                  <StyledA href="#">
                    <StyledLink>jobs@stitchzone.com</StyledLink>
                  </StyledA>
                  <StyledSpan>
                    There are many applicants, so in order to help us find the
                    right person to fit<br></br> with our company we ask that
                    you answer the following questions to be<br></br>{" "}
                    considered:
                  </StyledSpan>
                  <ul>
                    <ol>
                      1. Why is this <StyledSpan> Part-Time </StyledSpan> job
                      perfect for you?
                    </ol>
                    <ol>2. What are you currently doing?</ol>
                    <ol>3. Tell us something interesty about yourself</ol>
                    <ol>4. What do you do for fun?</ol>
                  </ul>
                  <StyldSpanBlack>
                    Do not apply if you are seeking FULL TIME employment.
                  </StyldSpanBlack>
                </Styledtd>
              </tr>
              <tr>
                <StyledTdTitle>Interviews:</StyledTdTitle>
                <Styledtd>
                  Are being conducted until filled.<br></br> What number and
                  time is best to contact you?
                </Styledtd>
              </tr>
            </tbody>
          </StyledTable>
        </Container>
      </>
    );
  }
}
