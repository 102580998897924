import styled from "styled-components";

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 20px;
  gap: 15px;
  div {
    display: grid;
    gap: 15px;
    width: auto;
    color: #1b43a1;
    &:hover {
      color: #0b69377c;
    }
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 50px;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 25px;
  }
`;

const Container = styled.div`
  padding: 0 100px;
  display: grid;
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Paragraph = styled.p`
  margin: 0;
`;
export { Container, Img, Wrapper, Paragraph };
