import { decrementLoader, incrementLoader } from "../actions";
import { authCookieName } from "../constants";
import { reduxStore } from "../reducer";
import { AuthUtils } from "../Utils";
import { BaseApiError } from "./BaseApiError";

export class BaseApiClient {
  private getAuthToken() {
    let authToken = "";
    const parsedCookies = AuthUtils.parseCookies();
    if (parsedCookies[authCookieName]) {
      authToken = parsedCookies[authCookieName];
    }
    return authToken;
  }

  protected async get<TResponseJson>(
    url: string,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    const authToken = this.getAuthToken();

    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }
    url = this.constructUrl(url);
    const res = await fetch(url, { headers });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }
    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }

    throw await this.handleError(res);
  }

  protected async postFormData<TResponseJson>(
    url: string,
    body: FormData,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    const authToken = this.getAuthToken();
    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }
    url = this.constructUrl(url);
    const res = await fetch(url, { method: "POST", body, headers });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }
    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }

    throw await this.handleError(res);
  }

  protected async postJson<TResponseJson>(
    url: string,
    body: object,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    headers.append("Content-Type", "application/json");
    const authToken = this.getAuthToken();
    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }
    url = this.constructUrl(url);
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }
    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }
    throw await this.handleError(res);
  }

  protected async putFormData<TResponseJson>(
    url: string,
    body: FormData,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    const authToken = this.getAuthToken();
    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }
    url = this.constructUrl(url);
    const res = await fetch(url, { method: "PUT", body, headers });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }
    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }
    throw await this.handleError(res);
  }

  protected async putJson<TResponseJson>(
    url: string,
    body: object,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    const authToken = this.getAuthToken();
    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }

    url = this.constructUrl(url);
    const res = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers,
    });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }
    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }
    throw await this.handleError(res);
  }

  protected async deleteJson<TResponseJson>(
    url: string,
    body: object,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    const authToken = this.getAuthToken();
    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }

    url = this.constructUrl(url);
    const res = await fetch(url, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers,
    });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }

    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }

    throw await this.handleError(res);
  }

  protected async deleteFormData<TResponseJson>(
    url: string,
    body: FormData,
    headers: Headers = new Headers(),
    showLoader: boolean = true
  ): Promise<TResponseJson> {
    if (showLoader) {
      reduxStore.dispatch(incrementLoader());
    }
    const authToken = this.getAuthToken();
    if (authToken) {
      headers.append("Authorization", `jwt ${authToken}`);
    }
    url = this.constructUrl(url);
    const res = await fetch(url, { method: "DELETE", body, headers });
    if (showLoader) {
      reduxStore.dispatch(decrementLoader());
    }
    if (res.ok) {
      return (await res.json()) as TResponseJson;
    }

    throw await this.handleError(res);
  }

  private async handleError(res: Response) {
    const resText = await res.text();
    console.log("resText : ", resText);
    // handle things in this switch case

    switch (res.status) {
      case 401:
        window.location.pathname = "/login";
        return new BaseApiError(res.status, res.statusText, {
          apiErrorMessage: resText,
        });

      case 404:
        return new BaseApiError(res.status, res.statusText, {
          apiErrorMessage: resText,
        });
    }

    return new BaseApiError(res.status, res.statusText, {
      apiErrorMessage: resText,
    });
  }

  protected constructUrl(
    path: string,
    queryParams?: Record<string, string>,
    pathParams?: Record<string, string>
  ): string {
    let queryString = "";
    //const backendDomain = process.env.BASE_URL || "http://localhost:8000";
    //const backendDomain = process.env.BASE_URL || "http://13.232.137.152:3000";
    const backendDomain = process.env.BASE_URL || "https://favorsbythebay.com/apis";
    let baseUrl = path.startsWith("http") ? path : `${backendDomain}/${path}`;

    if (pathParams) {
      Object.keys(pathParams).forEach((key) => {
        baseUrl.replace(key, pathParams[key]);
      });
    }

    if (queryParams) {
      queryString = new URLSearchParams(queryParams).toString();
      baseUrl = `${baseUrl}?${queryString}`;
    }

    return baseUrl;
  }

  generateFormData(data: any) {
    let formData = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }
}
