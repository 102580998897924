import styled from "styled-components";
import { primaryColor } from "../../constants";

const Container = styled.div`
  padding: 0 30px;
`;

const StyledHeader = styled.h2`
  color: black;
`;

const StyledSpan = styled.span`
  color: #971616;
  font-weight: 600;
`;

const StyledP = styled.p`
  color: black;
  font-size: 18px;
  font-weight: 550;
`;

const StyledPBlue = styled.p`
  color: blue;
  font-size: 16px;
  font-weight: 550;
`;

const Primary = styled.p`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${primaryColor};
`;
const StyledTable = styled.table`
  width: 750px;
  height: 800px;
  border-collapse: collapse;
`;
const Styledtd = styled.td`
  border: 2px solid black;
  margin: 0;
  padding: 0;
`;
const StyledTdTitle = styled.td`
  border: 2px solid black;
  color: blue;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;
const StyledLink = styled.h1`
  color: blue;
  font-size: 40px;
  font-weight: 700;
  padding: 5px;
  margin-top: 0;
  color: blue;
  &:hover {
    color: #0b69377c;
  }
`;
const StyledA = styled.a`
  text-decoration: none;
`;

const StyldSpanBlack = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: black;
`;

const Danger = styled.p``;
export {
  Container,
  Primary,
  Danger,
  StyledHeader,
  StyledSpan,
  StyledP,
  StyledPBlue,
  StyledTable,
  Styledtd,
  StyledTdTitle,
  StyledLink,
  StyledA,
  StyldSpanBlack,
};
