import styled from "styled-components";
import { Input } from "@happeouikit/form-elements";
import {
  marginL,
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
  themeWhite,
} from "../../constants";

const MainContainer = styled.div`
  /* min-height: 500px; */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  display: flex;
  flex-direction: column;
`;

const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const SecondaryContainer = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
`;

const Wrapper = styled.div``;

const Title = styled.div`
  width: 40%;
`;

const Field = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
`;

const ModifierContainer = styled.div`
  margin-top: ${marginM};
`;

const ModifierBreakDownTable = styled.table`
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
`;

const FormGroup = styled.div`
  margin-bottom: ${marginM};
`;

const LeftSection = styled.section`
  flex: 1;
  margin-right: ${marginL};
`;

const RightSection = styled.section`
  flex: 1;
`;

const FormGroupColumn = styled.div`
  margin-bottom: ${marginM};
  display: flex;
  justify-content: space-between;
`;

const BottomSection = styled.section``;

const ButtonAlpha = styled.button`
  all: unset;
  text-align: left;
  font: normal normal 600 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #ffffff;
  background: #142f8f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
`;

const ModalWrapper = styled.div`
  width: 100%;
`;

const ModalContainer = styled.div`
  display: flex;
`;

const AccordionCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const AccordionContainer = styled.div`
  padding: 0 30px;
`;

const AccordionHeader = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? themeWhite : primaryColor)};
  background-color: ${(props) => (props.active ? primaryColor : themeWhite)};
  font-size: 24px;
  padding: 1px;

  & h5 {
    margin: 0;
    padding: 10px 0 10px 10px;
  }
`;

const AccordionCollapse = styled.div<{ collapse: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
  height: ${(props) => (props.collapse ? "auto" : "0")};
  font-size: 16px;
  line-height: 1.5;
  padding: ${(props) => (props.collapse ? "10px 10px 10px 10px" : "0")};
`;

export {
  MainContainer,
  SettingHeader,
  SecondaryContainer,
  Wrapper,
  Title,
  Field,
  ModifierContainer,
  ModifierBreakDownTable,
  FormContainer,
  FormGroup,
  LeftSection,
  RightSection,
  FormGroupColumn,
  BottomSection,
  ButtonAlpha,
  ModalWrapper,
  ModalContainer,
  AccordionCard,
  AccordionContainer,
  AccordionHeader,
  AccordionCollapse,
};
