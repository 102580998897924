import styled from "styled-components";
import { cartClient } from "../../ApiClients/CartClient";

const CartCTA = ({ cartItemCount, addOrder, addShipping, hasShippingInfo }) => {
  return (
    <Flex>
      <ButtonBeta onClick={() => (window.location = "/")}>
        Continue Shopping
      </ButtonBeta>
      {cartItemCount > 0 && !hasShippingInfo && (
        <ButtonAlpha onClick={addShipping}>Add Shipping</ButtonAlpha>
      )}
      {cartItemCount > 0 && hasShippingInfo && (
        <ButtonAlpha onClick={addOrder}>Request for Quotation</ButtonAlpha>
      )}
    </Flex>
  );
};

export default CartCTA;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
  border-top: 1px solid #ececec;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 88%;
  // margin: 0 20px;
`;

const ButtonBeta = styled.button`
  all: unset;
  text-align: left;
  font: normal normal 600 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #ffffff;
  opacity: 1;
  padding: 5px;
  margin-right: 20px;
  color: #142f8f;
  cursor: pointer;
`;

const ButtonAlpha = styled.button`
  all: unset;
  text-align: left;
  font: normal normal 600 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #ffffff;
  background: #142f8f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
`;
