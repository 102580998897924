import React, { RefObject, createRef } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { IAppState, ILandingState } from "../../models";
import { landingClient } from "../../ApiClients/LandingClient";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeaderContent from "./HeaderContent";

import { GenderCategory } from "../../widgets/genderCategory";
import Carousel from "../../components/Carousel";
import Marquee from "../../components/Marquee";
import { SliderContainer } from "./styled.component";
import SignupForFree from "./SignupForFree";

interface LandingPageProps {
  landing: ILandingState;
  getLanding(): void;
}

interface LandingPageState {
  isComponentMounted: boolean;
}

export class LandingPageComponent extends React.Component<
  LandingPageProps,
  LandingPageState
> {
  private carouselRef: RefObject<HTMLDivElement>;
  constructor(props: LandingPageProps) {
    super(props);
    this.state = {
      isComponentMounted: false,
    };
    this.carouselRef = createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    this.props.getLanding();
  }

  componentDidUpdate(): void {
    window.addEventListener("hashchange", this.handleCarouselMount);

    if (this.state.isComponentMounted && this.carouselRef.current) {
      const hash = window.location.hash.substring(1);
      if (hash === "carousel") {
        this.carouselRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }

  handleCarouselMount = () => {
    this.setState({ isComponentMounted: true });
  };
  

  render() {
    const { isFetching, landingPage, specialOffer, marquee, middleSection } =
      this.props.landing;
    const isEmpty = landingPage.length === 0;
    const isMarqueeEmpty = marquee.length === 0;
    const isMiddleSectionEmpty = 1; //middleSection.length === 0;
    const isOffersEmpty = specialOffer.length === 0;
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 5000,
      cssEase: "linear",
    };
    return (
      <>
        {isFetching && <h1>Loading...</h1>}
        {!isEmpty && (
          <div style={{marginTop: "-20px"}}>
            <Slider className="Hero" {...settings}>
              {landingPage.map((landing) => (
                <SliderContainer key={landing._id}>
                  <img src={landing.image} alt="" onClick={() => {if(landing.button.length > 0) { window.open(landing.button[0].url, '_blank')}}} style={{"cursor":"pointer"}} />
                  {/* <HeaderContent
                    position={landing.position}
                    title={landing.title}
                    description={landing.description}
                  /> */}
                </SliderContainer>
              ))}
            </Slider>
          </div>
        )}
        <div style={{marginBottom:"50px"}}></div>
        {!isMarqueeEmpty && <Marquee>{marquee[0].name}</Marquee>}
        {!isMiddleSectionEmpty && <GenderCategory promote={middleSection} />}
        {!isOffersEmpty && (
          <div ref={this.carouselRef} onLoad={this.handleCarouselMount}>
            <Carousel offers={specialOffer} />
          </div>
        )}
        <SignupForFree />
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    landing: state.landing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getLanding: () => landingClient.getLanding(dispatch),
  };
};

export const LandingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageComponent);
