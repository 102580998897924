import { AnyAction } from "redux";
import { PrimaryBgColor, PrimaryFontColor } from "../constants";
import { IFooterState } from "../models";
import Logo from "../images/Logo.png";

const initialState: IFooterState = {
  logoUrl: Logo,
  bgColor: PrimaryBgColor,
  fontColor: PrimaryFontColor,
  primaryMessage: "ONLINE GREEK STORE FOR ALL YOUR CUSTOM APPAREL",
  secondaryMessage:
    "Quality Custom Embroiderer and Manufacturer of Custom Greek Apparel and Clothing for each Nationwide & Local Fraternity and Sorority. We offer custom designs for clubs, organizations, athletic teams, small businesses and corporate accounts. Our goal is to bring your vision into reality.",
  categories: [
    {
      headerLabel: "About Us",
      children: [
        { label: "Product Photo gallery", path: "/photo-gallery" },
        { label: "Shipping Rates", path: "/terms" },
        { label: "Careers", path: "/careers" },
        { label: "FAQ", path: "/faq" },
        { label: "Terms & Conditions", path: "/terms" },
      ],
    },
    {
      headerLabel: "Quick Links",
      children: [
        { label: "Link 1", path: "/photo-gallery" },
        { label: "Link 2", path: "/shipping-rates" },
        { label: "Link 3", path: "/careers" },
        { label: "Link 4", path: "/faq" },
        { label: "Link 5", path: "/terms-and-condition" },
      ],
    },
    {
      headerLabel: "Men Products",
      children: [
        { label: "Sweatshirts", path: "/category/sweatshirts" },
        { label: "Tshirt", path: "/category/tshirt" },
        { label: "Shirt", path: "/category/shirt" },
        { label: "Jacket", path: "/category/jacket" },
        { label: "Cardigans", path: "/category/cardigans" },
      ],
    },
    {
      headerLabel: "Women Products",
      children: [
        { label: "Sweatshirts", path: "/category/sweatshirts" },
        { label: "Tshirt", path: "/category/tshirt" },
        { label: "Shirt", path: "/category/shirt" },
        { label: "Jacket", path: "/category/jacket" },
        { label: "Cardigans", path: "/category/cardigans" },
      ],
    },
  ],
  subscriber: []
};

export function footer(state: IFooterState = initialState, action: AnyAction) {
  switch (action.type) {
    default:
      return state;
  }
}
