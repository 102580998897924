import { AnyAction } from "redux";
import { ICartState } from "../models";
import {
  ADD_COLOR_TO_CART,
  ADD_PRICE_CART,
  GET_CART,
  UPDATE_PRICE_CART,
  REMOVE_PRICE_CART,
  UPDATE_APPLIQUE_TO_CART,
  UPDATE_EMBROIDART_TO_CART,
  UPDATE_PRODUCT_ID_TO_CART,
  UPDATE_QUANTITY_TO_CART,
  UPDATE_PREVIEW_IMAGES_TO_CART,
  UPDATE_SIZE_QTY_PRICE_CART,
  UPDATE_APPQUE_FR_PRICE_CART,
  UPDATE_APPQUE_BK_PRICE_CART,
  UPDATE_EMBDRY_FR_PRICE_CART,
  UPDATE_EMBDRY_BK_PRICE_CART,
  UPDATE_EMBDRY_LF_PRICE_CART,
  UPDATE_EMBDRY_RT_PRICE_CART,
  UPDATE_EMBDRY_LF_CUFF_PRICE_CART,
  UPDATE_EMBDRY_RT_CUFF_PRICE_CART,
  UPDATE_EMBDRY_COLLAR_PRICE_CART,
  UPDATE_EMBDRY_COLLAR_FLIPPED_UP_PRICE_CART,
  UPDATE_EMBDRY_HOOD_PRICE_CART,
  UPDATE_EMBDRY_HOOD_FORWARD_PRICE_CART,
  UPDATE_EMBDRY_HOOD_BACKWARD_PRICE_CART,
  ADD_SPECIAL_INSTRUCTION_TO_CART
} from "../actions";

const initialState = {
  cartData: {
    productId: "",
    customizedProducts: {
      colors: {
        colorName: "",
        colorCode: "",
        "size&quantity": [],
        images: {},
      },
      appliqueId: {
        front: {
          _id: "",
          name: "",
          stitchingType: {},
          organization: "",
          letterSize: {},
          letterPlacement: "",
          foreground: {
            foregroundMaterial: {},
            foregroundColor: {},
            threadColor: {},
          },
          background: {
            backgroundMaterial: {},
            backgroundColor: {},
            threadColor: {},
          },
          heatSealedLetter: {},
          specialInstruction: "",
          customFiles: [],
        },
        back: {
          _id: "",
          name: "",
          stitchingType: {},
          organization: "",
          letterSize: {},
          letterPlacement: "",
          foreground: {
            foregroundMaterial: {},
            foregroundColor: {},
            threadColor: {},
          },
          background: {
            backgroundMaterial: {},
            backgroundColor: {},
            threadColor: {},
          },
          heatSealedLetter: {},
          specialInstruction: "",
          customFiles: [],
        },
      },
      embroidaryId: [],
      previewImages: [],
    },
    customizedImages: {},
    sizeQtyPageTotalPrice: 0,
    appliqueFrontPageTotalPrice: 0,
    appliqueBackPageTotalPrice: 0,
    embroidaryFrontPageTotalPrice: 0,
    embroidaryBackPageTotalPrice: 0,
    embroidaryLeftPageTotalPrice: 0,
    embroidaryRightPageTotalPrice: 0,
    embroidaryLeftCuffPageTotalPrice: 0,
    embroidaryRightCuffPageTotalPrice: 0,
    embroidaryCollarPageTotalPrice: 0,
    embroidaryCollarFlippedUpPageTotalPrice : 0,
    embroidaryHoodPageTotalPrice: 0,
    embroidaryHoodForwardPageTotalPrice: 0,
    embroidaryHoodBackwardPageTotalPrice : 0,
    totalQuantity: 0,
    totalPrice: 0,
    currency: "$",
    specialInstruction: ""
  },
};

export function cart(state: any = initialState, action: AnyAction): ICartState {
  /* console.log("state.cartData.totalPrice : ", state.cartData.totalPrice);
  console.log("action.data.price : ", action?.data?.price);
  console.log("action.data.totalPrice : ", action?.data?.totalPrice); */
  switch (action.type) {
    case GET_CART:
      let cart = action.data.data;
      if (!state) {
        return { ...state, cartData: cart };
      }
      return { ...state, ...cart };
    case ADD_PRICE_CART:
      /* console.log(
        "ADD_PRICE_CART = state.cartData.totalPrice + action.data.price : ",
        state.cartData.totalPrice + action.data.price
      ); */
      let sum = state.cartData.totalPrice + action.data.price;
      return {
        ...state,
        cartData: {
          ...state.cartData,
          totalPrice: isNaN(sum) ? 0 : sum,
        },
      };
    case UPDATE_PRICE_CART:
      /* console.log(
        "UPDATE_PRICE_CART = action.data.totalPrice : ",
        action.data.totalPrice
      ); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          totalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case REMOVE_PRICE_CART:
      /* console.log(
        "REMOVE_PRICE_CART = state.cartData.totalPrice - action.data.price : ",
        state.cartData.totalPrice - action.data.price
      ); */
      let sub = state.cartData.totalPrice > action.data.price?(state.cartData.totalPrice - action.data.price):0;
      return {
        ...state,
        cartData: {
          ...state.cartData,
          totalPrice: isNaN(sub) ? 0 : sub,
        },
      };
    case UPDATE_SIZE_QTY_PRICE_CART:
      /* console.log("sizeQtyPageTotalPrice : ", action.data.price); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          sizeQtyPageTotalPrice: isNaN(action.data.price)
            ? 0
            : action.data.price,
        },
      };
    case UPDATE_APPQUE_FR_PRICE_CART:
      /* console.log("appliqueFrontPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          appliqueFrontPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_APPQUE_BK_PRICE_CART:
      /* console.log("appliqueBackPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          appliqueBackPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_FR_PRICE_CART:
      /* console.log("embroidaryFrontPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryFrontPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_BK_PRICE_CART:
      /* console.log("embroidaryBackPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryBackPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_LF_PRICE_CART:
      /* console.log("embroidaryLeftPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryLeftPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_RT_PRICE_CART:
      /* console.log("embroidaryRightPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryRightPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_LF_CUFF_PRICE_CART:
      /* console.log("embroidaryLeftCuffPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryLeftCuffPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_RT_CUFF_PRICE_CART:
      /* console.log("embroidaryRightCuffPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryRightCuffPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_COLLAR_PRICE_CART:
      /* console.log("embroidaryCollarPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryCollarPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      }; 
    case UPDATE_EMBDRY_COLLAR_FLIPPED_UP_PRICE_CART:
      /* console.log("embroidaryCollarFlippedUpPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryCollarFlippedUpPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case UPDATE_EMBDRY_HOOD_PRICE_CART:
      /* console.log("embroidaryHoodPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryHoodPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      }; 
    case UPDATE_EMBDRY_HOOD_FORWARD_PRICE_CART:
      /* console.log("embroidaryHoodForwardPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryHoodForwardPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      }; 
    case UPDATE_EMBDRY_HOOD_BACKWARD_PRICE_CART:
      /* console.log("embroidaryHoodbackwardPageTotalPrice : ", action.data.totalPrice); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          embroidaryHoodBackwardPageTotalPrice: isNaN(action.data.totalPrice)
            ? 0
            : action.data.totalPrice,
        },
      };
    case ADD_COLOR_TO_CART:
      return {
        ...state,
        cartData: {
          ...state.cartData,
          customizedProducts: {
            ...state.cartData.customizedProducts,
            colors: {
              ...action.data,
            },
          },
        },
      };
    case UPDATE_PREVIEW_IMAGES_TO_CART:
      /* console.log(action.data, "action.data"); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          customizedProducts: {
            ...state.cartData.customizedProducts,
            previewImages: action.data,
          },
        },
      };
    case UPDATE_QUANTITY_TO_CART:
      return {
        ...state,
        cartData: {
          ...state.cartData,
          totalQuantity: action.data.totalQuantity,
        },
      };
    case UPDATE_APPLIQUE_TO_CART:
      const front = action.data["front"];
      const back = action.data["back"];
      return {
        ...state,
        cartData: {
          ...state.cartData,
          customizedProducts: {
            ...state.cartData.customizedProducts,
            appliqueId: {
              front: {
                ...front,
              },
              back: {
                ...back,
              },
            },
          },
        },
      };
    case UPDATE_EMBROIDART_TO_CART:
      /* console.log(action.data, "action.data"); */
      return {
        ...state,
        cartData: {
          ...state.cartData,
          customizedProducts: {
            ...state.cartData.customizedProducts,
            embroidaryId: [...action.data],
          },
        },
      };
    case UPDATE_PRODUCT_ID_TO_CART:
      return {
        ...state,
        cartData: {
          ...state.cartData,
          productId: action.data,
        },
      };
    case ADD_SPECIAL_INSTRUCTION_TO_CART:
      return {
        ...state,
        cartData: {
          ...state.cartData,
          specialInstruction: action.data,
        },
      }
    default:
      return state;
  }
}
