import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Loader } from "@happeouikit/loaders";

export const PaymentPage = () => {
  const [screenLoader, setScreenLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setScreenLoader(false);
    }, 3000);
  }, []);
  return (
    <>
      {screenLoader && (
        <LoaderOverlay>
          <LoaderContainer>
            <Loader size="large"></Loader>
          </LoaderContainer>
        </LoaderOverlay>
      )}
      <Box m="100">Payment page in progress</Box>
    </>
  );
};

const Box = styled.div`
  display: grid;
  margin: ${(props) => props.m}px;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: calc(50vh - 150px);
  right: calc(50vw - 10px);
  z-index: 999999;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #8080806b;
  z-index: 999999;
`;
