import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { header } from "./header.reducer";
import { footer } from "./footer.reducer";
import { login } from "./login.reducer";
import { loader } from "./loader.reducer";
import { categories } from "./category.reducer";
import { applique } from "./applique.reducer";
import { createLogger } from "redux-logger";
import { landing } from "./landing.reducer";
import { products } from "./product.reducer";
import { cart } from "./cart.reducer";
import { announcement } from "./announcement.reducer";
import { specification } from "./specification.reducer";
import { embroidary } from "./embroidary.reducer";
import { profile, profileAddress, shippingRates } from "./profile.reducer";

const appReducer = combineReducers({
  header,
  footer,
  login,
  categories,
  loader,
  landing,
  applique,
  products,
  cart,
  announcement,
  specification,
  embroidary,
  profile,
  profileAddress,
  shippingRates,
});

const logger = createLogger({
  diff: true,
});

export const reduxStore = createStore(
  appReducer,
  composeWithDevTools(
    applyMiddleware()
    // other store enhancers if any
  )
);
