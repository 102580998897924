import React, { useEffect } from "react";
import { Dispatch } from "redux";
import User from "../../components/svgIcons/User";
import Edit from "../../components/svgIcons/Edit";
import { IAppState, IHeaderState } from "../../models";
import { primaryColor } from "../../constants";

import { headerClient } from "../../ApiClients/HeaderClient";
import {
  Container,
  Logo,
  HeaderActions,
  CategoryLinks,
  DropDown,
  ProfileDropDown,
  SubContainer,
  LinkList,
  BtnPrimary,
  BtnSecondary,
  ListDropdown,
  List,
  ExploreBtn,
} from "./styled.component";
import { connect } from "react-redux";
import SZLogo from "../../images/stitchzone_logo_slogan_white.png";

interface HeaderProps {
  header: IHeaderState;
  getHeader(): void;
}

interface HeaderState {}

export class HeaderComponent extends React.Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.props.getHeader();
  }

  deleteCookie(name: string): void {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  logout = ():void => {
    sessionStorage.removeItem('userDetails');
    /* remove cookie authDetails */
    this.deleteCookie("authDetails");
    window.location.href="/login";
  }

  render() {
    return (
      <>
        <Container bgColor={this.props.header.headerBgColor}>
          <a href="/" style={{margin:"10px 10px 10px 5px"}}>
            <Logo src={SZLogo} alt="stitch zone logo" />
          </a>
          <HeaderActions>
            {this.props.header.categories.map((category) => {
              return (
                <CategoryLinks
                  color={this.props.header.headerFontColor}
                  href={`/catalog/${category._id}`}
                  key={category._id}
                  selected={window.location.pathname.indexOf(category._id) !== -1?true:false}
                >
                  {category.name}
                </CategoryLinks>
              );
            })}
            <CategoryLinks
              color={this.props.header.headerFontColor}
              href={`/clearance`}
              key="clearance"
              selected={window.location.pathname.indexOf("clearance") !== -1?true:false}
            >
              Clearance
            </CategoryLinks>
            <CategoryLinks
              color={this.props.header.headerFontColor}
              href={`#`}
              key="refernce"
              selected={['fundraise', 'specifications', 'design', 'fonts', 'colors', 'size-and-location', 'contact'].indexOf(window.location.pathname.replace("/", "")) !== -1?true:false}
            >
              References
              <DropDown style={{width: "150px"}}>
                <CategoryLinks color={primaryColor} href="/fundraise" selected={window.location.pathname.indexOf("fundraise") !== -1?true:false}>
                  Fundraise
                </CategoryLinks>
                <br />
                <CategoryLinks color={primaryColor} href="/specifications" selected={window.location.pathname.indexOf("specifications") !== -1?true:false}>
                  Specifications
                </CategoryLinks>
                <br />{" "}
                <CategoryLinks color={primaryColor} href="/design" selected={window.location.pathname.indexOf("design") !== -1?true:false}>
                  Design
                </CategoryLinks>
                <br />{" "}
                <CategoryLinks color={primaryColor} href="/fonts" selected={window.location.pathname.indexOf("fonts") !== -1?true:false}>
                  Fonts
                </CategoryLinks>
                <br />{" "}
                <CategoryLinks color={primaryColor} href="/colors" selected={window.location.pathname.indexOf("colors") !== -1?true:false}>
                  Colors
                </CategoryLinks>
                <br />{" "}
                <CategoryLinks color={primaryColor} href="/size-and-location" selected={window.location.pathname.indexOf("size-and-location") !== -1?true:false}>
                  Size and Location
                </CategoryLinks>
                <CategoryLinks color={primaryColor} href="/#carousel">Specials</CategoryLinks>
                <CategoryLinks color={primaryColor} href="/contact" selected={window.location.pathname.indexOf("contact") !== -1?true:false}>Contact</CategoryLinks>
              </DropDown>
            </CategoryLinks>
            
            {sessionStorage.getItem("userDetails") != null ? (<CategoryLinks
              color={this.props.header.headerFontColor}
              href={`#`}
              key="refernce"
            >
              <User />
              <ProfileDropDown style={{width: "106px"}}>
                <CategoryLinks color={primaryColor} href="/my-profile" selected={window.location.pathname.indexOf("my-profile") !== -1?true:false}>
                  My Profile
                </CategoryLinks>
                <br />
                <CategoryLinks color={primaryColor} href="/my-orders" selected={window.location.pathname.indexOf("my-orders") !== -1?true:false}>
                  My Orders
                </CategoryLinks>
                <br />
                <CategoryLinks color={primaryColor} href="javascript:void(0)" onClick={this.logout}>
                  Logout
                </CategoryLinks>
              </ProfileDropDown>
            </CategoryLinks>
            ) : (
              <a href="/login">
                <User />
              </a>
            )}
            &nbsp;&nbsp;
          </HeaderActions>
          {/* {window.location.pathname === "/" && (
            <SubContainer>
              <LinkList>
                {[
                  "sweetshirt",
                  "jacket",
                  "cardian",
                  "shirt",
                  "stole",
                  "hats",
                  "bags",
                  "accessroies",
                  "off the rack",
                  "imprint",
                ].map((list) => (
                  <List key={list}>
                    <a>{list}</a>
                    <ListDropdown>
                      <ExploreBtn>Explore More Products</ExploreBtn>
                    </ListDropdown>
                  </List>
                ))}
              </LinkList>
              <BtnPrimary href="/sign-up">Join Now</BtnPrimary>
              <BtnSecondary href="/catalog/">Start Design</BtnSecondary>
            </SubContainer>
          )} */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    header: state.header,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getHeader: () => headerClient.getHeader(dispatch),
  };
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
