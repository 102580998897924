import { AnyAction } from "redux";
import { GET_PRODUCTS, SELECTED_PRODUCT } from "../actions";
import { IProductState } from "../models";

const initialStateProduct: IProductState = {
  list: [],
  selectedProduct: {
    appliqueId: [],
    cName: "",
    categoryId: {
      _id: "",
      name: "",
      image: "",
    },
    colors: [],
    currency: "",
    description: "",
    embroidaryId: [],
    gender: "",
    greekLetterSizeId: [],
    heatSealedLetter: {
      isactive: false,
      cost: '0'
    },
    imgSize: {
      height: 0,
      width: 0,
    },
    includes: "",
    item: "",
    letterplacementId: [],
    name: "",
    price: 0,
    priority: 0,
    sName: "",
    size: [],
    stitchingTypeId: [],
    subCategoryId: {
      _id: "",
      name: "",
      image: "",
    },
    totalQuantity: 0,
    typeDetailId: {
      _id: "",
      name: "",
    },
    clearance: false,
    typeId: {
      _id: "",
      name: "",
    },
  },
};

export function products(
  state: IProductState = initialStateProduct,
  action: AnyAction
) {
  switch (action.type) {
    case GET_PRODUCTS:
      let list = action.data.data;
      state.list = list;
      return { ...state };
    case SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.data };
    default:
      return state;
  }
}
