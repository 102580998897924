import React, { Component } from "react";
import {
  ContainerTry,
  SignupBtn,
  TextContainer,
  SignUpBtnContainer,
  SignUpP,
  SpanTag,
  ContainerBox,
} from "./styled.component";

class SignupForFree extends Component {
  constructor(props: any) {
    super(props);
    this.redirectToLogin = this.redirectToLogin.bind(this);
  }

  redirectToLogin() {
    window.location.pathname = "sign-up";
  }

  render() {
    return (
      <ContainerBox>
        <ContainerTry>
          <TextContainer>
            <SpanTag>Try StitchZone Today</SpanTag>
            <SignUpP>
              Personalize your clothes and bring imagination to reality with
              StitchZone
            </SignUpP>
          </TextContainer>

          <SignUpBtnContainer>
            <SignupBtn onClick={this.redirectToLogin}>
              Sign Up for Free
            </SignupBtn>
          </SignUpBtnContainer>
        </ContainerTry>
      </ContainerBox>
    );
  }
}

export default SignupForFree;
