export interface IValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export class Validation {
  public static validateEmail(mail: string): IValidationResult {
    if (!mail?.length) {
      return {
        isValid: false,
        errorMessage: "Email is required",
      };
    }

    return {
      isValid: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail),
      errorMessage: "",
    };
  }

  public static validateName(name: string): IValidationResult {
    if (!name?.length) {
      return {
        isValid: false,
        errorMessage: "Name is required",
      };
    }

    return {
      isValid: /^[A-Za-z\s]+$/.test(name),
      errorMessage: "",
    };
  }

  public static validatePhone(phone: string): IValidationResult {
    if (!phone?.length) {
      return {
        isValid: false,
        errorMessage: "Phone is required",
      };
    }

    return {
      isValid:
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
          phone
        ),
      errorMessage: "",
    };
  }

  public static validatePassword(password: string): IValidationResult {
    if (!password?.length) {
      return {
        isValid: false,
        errorMessage: "Password is required",
      };
    }

    if (password?.length < 6) {
      return {
        isValid: true,
        errorMessage: "password must be atleast 6 characters",
      };
    }

    return {
      isValid: true,
      errorMessage: "",
    };
  }

  public static isValidUrl(expectedUrl: string) {
    try {
      new URL(expectedUrl);
    } catch {
      return false;
    }

    return true;
  }
}
