export const FloaterIcon2 = () => (
  <svg
    id="Choose_Style"
    data-name="Choose Style"
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g
      id="_11e04bee5e09a1f47f7c7b350bad8677"
      data-name="11e04bee5e09a1f47f7c7b350bad8677"
    >
      <path id="Path_146" data-name="Path 146" d="M0,0H36V36H0Z" fill="none" />
      <path
        id="Path_147"
        data-name="Path 147"
        d="M3.055,25.876l1.91.766V14.286L1.5,22.3a2.725,2.725,0,0,0,1.554,3.572Zm27.8-5.063-7.07-16.38A2.871,2.871,0,0,0,21.2,2.75a2.976,2.976,0,0,0-1.126.205L9.57,7.129A2.739,2.739,0,0,0,7.816,9.592a2.464,2.464,0,0,0,.214,1.095l7.07,16.38a2.851,2.851,0,0,0,2.609,1.683,3.056,3.056,0,0,0,1.1-.205L29.3,24.371a2.692,2.692,0,0,0,1.554-3.558Zm-13.114,5.2L10.667,9.647l10.477-4.16h.014l7.056,16.353L17.737,26.013Z"
        transform="translate(1.295 2.75)"
        fill="#142f8f"
      />
      <circle
        id="Ellipse_16"
        data-name="Ellipse 16"
        cx="2"
        cy="2"
        r="2"
        transform="translate(15 12)"
        fill="#142f8f"
      />
      <path
        id="Path_148"
        data-name="Path 148"
        d="M5.88,26.09a4.012,4.012,0,0,0,4,4h2.9L5.88,13.41Z"
        transform="translate(2.446 1.41)"
        fill="#142f8f"
      />
    </g>
  </svg>
);
