import React, { ChangeEvent } from "react";
import { Dispatch } from "redux";
import {
  IAppState,
  CategoryResponseDatum,
  ISubcategory,
  IProduct,
} from "../../models";
import { connect } from "react-redux";
import { Dropdown } from "@happeouikit/form-elements";
import { categoryClient } from "../../ApiClients/ProductClient";
import { CategoriesPage } from "./Categories.page";
import { Title } from "../../components/Title";
import {
  Container,
  Frame,
  Breadcrum,
  Crums,
  Flex,
  SearchContainer,
  SearchInput,
} from "./styled.component";

interface CategoriesPageProps {
  categories: any | CategoryResponseDatum[];
  subCategories: ISubcategory[];
  products: Array<IProduct>;
  getCategories(): void;
  getSubCategories(id: string): void;
  getProducts(id: string): void;
}

export enum PAGE_TYPE {
  "CAT" = "CAT",
  "SUBCAT" = "SUBCAT",
  "PRODUCT" = "PRODUCT",
}

enum PAGE_TITLE {
  "CAT" = "CATEGORY",
  "SUBCAT" = "SUBCATEGORY",
  "PRODUCT" = "PRODUCTS",
}

interface CategoriesPageState {
  pageType: PAGE_TYPE;
  searchText: string;
  sort: string;
}

export class CatalogPageComponent extends React.Component<
  CategoriesPageProps,
  CategoriesPageState
> {
  constructor(props: any) {
    super(props);

    // const allPathVars = window.location.pathname.split("/");
    let pageType = PAGE_TYPE.CAT;
    let searchText = "";
    let sort = "ASC";

    if (window.location.pathname.endsWith("/subcategories")) {
      pageType = PAGE_TYPE.SUBCAT;
    } else if (window.location.pathname.endsWith("/products")) {
      pageType = PAGE_TYPE.PRODUCT;
    }

    this.state = {
      pageType,
      searchText,
      sort,
    };

    this.itemSelectHandler = this.itemSelectHandler.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount(): void {
    console.log("hereeeeee");
    const {
      props: { getCategories, getSubCategories, getProducts },
      state: { pageType },
    } = this;
    const allPathVars = window.location.pathname.split("/");

    getCategories();

    if (window.location.pathname.endsWith("/subcategories")) {
      getSubCategories(allPathVars[2]);
    }

    if (window.location.pathname.endsWith("/products")) {
      getSubCategories(allPathVars[2]);
    }

    if (pageType) {
      getProducts(allPathVars[4]);
    }
  }

  itemSelectHandler(id: string) {
    const {
      state: { pageType },
    } = this;

    if (pageType === PAGE_TYPE.CAT) {
      window.location.pathname = `/catalog/${id}/subcategories`;
    } else if (pageType === PAGE_TYPE.SUBCAT) {
      window.location.pathname = window.location.pathname + `/${id}/products`;
    } else if (pageType === PAGE_TYPE.PRODUCT) {
      window.location.pathname = window.location.pathname + `/${id}/design`;
    }
  }

  handleSearch(event: ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, searchText: event.target.value });
  }
  handleSort(val: string) {}

  render() {
    const {
      props: { categories, subCategories, products },
      state: { pageType },
    } = this;
    let data: Array<ISubcategory | CategoryResponseDatum | IProduct> = [];
    if (pageType === PAGE_TYPE.CAT) {
      data = categories;
    } else if (pageType === PAGE_TYPE.SUBCAT) {
      data = subCategories;
    } else if (pageType === PAGE_TYPE.PRODUCT) {
      if (this.state.sort === "ACS") {
        data = products.sort((a, b) => a.price - b.price);
      } else {
        data = products.sort((a, b) => b.price - a.price);
      }
    }

    return (
      <>
        <Flex>
          <Title>{PAGE_TITLE[pageType]}</Title>
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder="Search..."
              value={this.state.searchText}
              onChange={this.handleSearch}
            />
            {pageType === PAGE_TYPE.PRODUCT && (
              <>
                &nbsp;&nbsp;
                <Dropdown
                  options={[
                    { label: "Price ASC", value: "ACS" },
                    { label: "Price DSC", value: "DSC" },
                  ]}
                  onChange={(val: string) => this.handleSort(val)}
                  placeholder="Sort"
                />
              </>
            )}
          </SearchContainer>
        </Flex>
        {/* <Breadcrum>
          <Crums>
            <a href="#">Category</a>
          </Crums>
          <Crums>
            <a href="#">Sub-Category</a>
          </Crums>
          <Crums>
            <a href="#">Product</a>
          </Crums>
        </Breadcrum> */}
        <Container>
          <CategoriesPage
            isProduct={pageType === PAGE_TYPE.PRODUCT}
            data={data as any}
            filterText={this.state.searchText}
            handleClick={this.itemSelectHandler}
            pageType={pageType as any}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    categories: state.categories.list,
    subCategories: state.categories.subcategories,
    products: state.categories.productList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCategories: () => categoryClient.getCategory(dispatch),
    getProducts: (subCatId: string) =>
      categoryClient.getProduct(subCatId, dispatch),
    getSubCategories: (catId: string) =>
      categoryClient.getSubCategory(catId, dispatch),
  };
};

export const CatalogPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogPageComponent);
