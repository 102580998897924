import React, {useState,useEffect,ChangeEvent} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    LeftSection,
    FormGroup,
    FormGroupColumn,
    RightSection,
    ModalWrapper,
    ModalContainer,
  } from "./styled.component";


import { Modal } from "@happeouikit/modals";

import { Input, Dropdown } from "@happeouikit/form-elements";

import { TextBeta, TinyText } from "@happeouikit/typography";
import { toast } from "@happeouikit/toast";

import { paymentClient } from "../../ApiClients/PaymentClient";

type paymentFormProps = {
  title: string;
  isOpen: boolean;
  close(): void;
  onDone(): void;
  disabled?: boolean;
  selectedOrder?: any;
};

const PaymentForm = ({title, isOpen, selectedOrder, disabled, onDone, close} : paymentFormProps) => {
  const [orderId, setOrderId] = useState("");
  const [fullName, setFullName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvv, setCvv] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedOrder) {
      setOrderId(selectedOrder.orderId);
    }
  }, [selectedOrder]);

  const handlePayment = () => {
    
    const payload = {
      "orderId" : selectedOrder._id,
      "cardDetails" : {
        "cardNumber" : cardNumber,
        "expiry" : expiryMonth+"/"+expiryYear,
        "cvc" : cvv
      }
    }
    console.log(payload);

    paymentClient.makePayment(payload).then((paymentData) => {
      console.log(paymentData);
      if (
        paymentData?.message != null &&
        paymentData?.error == null
      ) {
        toast.success({
          message: `${paymentData?.message}`,
          delay: 4000,
        });
  
        onDone();
        close();
      }
  
      if (paymentData?.error != null) {
        toast.error({
          message: `${paymentData?.error}`,
          delay: 4000,
        });
      }
      
    });
  }

  return (<>
    <Modal
        isOpen={isOpen}
        close={close}
        headerText={<span style={{fontFamily: "helvetica sans-serif !important;", fontSize: "x-large"}}>{title}</span> || ""}
        width={"984px"}
        disabled={disabled}
        okText={"Save"}
        okCallback={() => {
          handlePayment();
        }}
        cancelText={"Cancel"}
        overlayStyle={{ zIndex: "1061" }}
      >
        <ModalWrapper>
          <ModalContainer >
            <LeftSection>
              <FormGroup>
                <Input
                  placeholder="Full Name"
                  autoComplete="off"
                  value={fullName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFullName(e.target.value)
                  }
                  label="Full Name"
                  style={{fontFamily: "helvetica sans-serif !important;"}}
                />
              </FormGroup>
              <FormGroupColumn>
                <div>
                  <Dropdown
                    label="Expire Month"
                    options={[
                      { label: "01", value: "01" },
                      { label: "02", value: "02" },
                      { label: "03", value: "03" },
                      { label: "04", value: "04" },
                      { label: "05", value: "05" },
                      { label: "06", value: "06" },
                      { label: "07", value: "07" },
                      { label: "08", value: "08" },
                      { label: "09", value: "09" },
                      { label: "10", value: "10" },
                      { label: "11", value: "11" },
                      { label: "12", value: "12" } 
                    ]}
                    onChange={(selectedOption: any) =>
                      setExpiryMonth(selectedOption.value)
                    }
                    placeholder="Select Month"
                  />
                </div>
                <div>
                  <Dropdown
                    label="Expire Year"
                    options={[
                      { label: "2024", value: "24" },
                      { label: "2025", value: "25" },
                      { label: "2026", value: "26" },
                      { label: "2027", value: "27" },
                    ]}
                    onChange={(selectedOption: any) =>
                      setExpiryYear(selectedOption.value)
                    }
                    placeholder="Select Year"
                  />
                </div>
                {/* <Input
                  placeholder="mm/yy"
                  autoComplete="off"
                  value={expiry}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setExpiry(e.target.value)
                  }
                  label="Expiry"
                /> */}
              </FormGroupColumn>
            </LeftSection>
            <RightSection>
              <FormGroup>
                <Input
                  placeholder="Card Number"
                  autoComplete="off"
                  value={cardNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCardNumber(e.target.value)
                  }
                  label="Card Number"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  placeholder="CVV"
                  autoComplete="off"
                  value={cvv}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCvv(e.target.value)
                  }
                  label="CVV"
                />
              </FormGroup>
            </RightSection>
          </ModalContainer>
        </ModalWrapper>
      </Modal>
  </>)
}

export default PaymentForm;