import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getLanding } from "../actions";
import { LandingResponse } from "../models";

class LandingClient extends BaseApiClient {
  public async getLanding(dispatch: Dispatch) {
    try {
      const landingData = await this.get<LandingResponse>("public/homepage");
      dispatch(getLanding(landingData));
    } catch (error) {
      console.log(error);
    }
  }
}

export const landingClient = new LandingClient();
