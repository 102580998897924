import React, {useState, useEffect, ChangeEvent} from "react";

import { useSelector, useDispatch } from "react-redux";
import { IAppState, IFooterState } from "../../models";
import { Copyright } from "./Copyright";
import {
  Wapper,
  Container,
  Logo,
  BrandTitle,
  BrandMessage,
  ColTitle,
  List,
  SocialList,
  Item,
  Link,
  ButtonAlpha
} from "./styled.component";


import socialImageFacebook from "../../images/facebook.png";
import socialImageInstagram from "../../images/instagram.png";
import socialImagePinterest from "../../images/pintrest.png";
import socialImageX from "../../images/x.png";

import { Input } from "@happeouikit/form-elements";

import { footerClient } from "../../ApiClients/FooterClient";

import { toast } from "@happeouikit/toast";

interface FooterProps {
  isActive: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isActive }) => {
  
  const { primaryMessage, secondaryMessage, categories, logoUrl } = useSelector<
    IAppState,
    IFooterState
  >((props) => props.footer);
  isActive = true;

  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const addSubscriber = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if(email != ""){
      const subscriberDataResponse = await footerClient.postSubscriber({
        email:email
      }, dispatch);

      if (subscriberDataResponse?.data != null) {
        toast.success({
          message: `${subscriberDataResponse?.message}`,
          delay: 4000,
        });
      } else {
        toast.error({
          message: `${subscriberDataResponse?.message}`,
          delay: 4000,
        });
      }
    }
  }

  return (
    <Wapper themed={isActive}>
      {/* <Logo src={logoUrl} /> */}
      <Container>
        <div style={{ flexBasis: "30%", paddingRight: "20px" }}>
          {/* <BrandTitle themed={isActive}>{primaryMessage}</BrandTitle>
          <BrandMessage themed={isActive}>{secondaryMessage}</BrandMessage> */}
          {/* <p>Be the first to know about arrivals, sales and more.</p> */}
          <BrandMessage themed={isActive}>{`Be the first to know about arrivals, sales and more.`}</BrandMessage>
          <div style={{marginBottom:"20px"}}></div>
          <div style={{justifyContent:"space-between", position: "relative"}}>
            <Input
              placeholder="Enter your email"
              autoComplete="off"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim())}
              label=""
              style={{width: "65%"}}
            />
            <ButtonAlpha style={{ height: "fit-content", position: "absolute", left: "66%", top: "0%" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => addSubscriber(e)}>Submit</ButtonAlpha>
          </div>
          <div style={{marginBottom:"20px"}}></div>
          <BrandTitle themed={isActive}>{`Follow Us`}</BrandTitle>
          <SocialList>
            <Item key="facebook">
              <Link
                themed={isActive}
                href="https://m.facebook.com/profile.php?id=100063908976508" target="_blank"
              >
                <img src={socialImageFacebook} width="24" height="24" />
                {/* <div style={{backgroundImage: "url('/StitchZone_files/social.jpeg')", width: "50px", height: "50px", backgroundPosition: "-110px -20px"}}></div> */}
              </Link>
            </Item>
            <Item key="Instagram">
              <Link
                themed={isActive}
                href="https://www.instagram.com/stitchzone/?hl=en" target="_blank"
              >
                <img src={socialImageInstagram} width="24" height="24" />
                {/* <div style={{backgroundImage: "url('/StitchZone_files/social.jpeg')", width: "50px", height: "50px", backgroundPosition: "-110px -110px"}}></div> */}
              </Link>
            </Item>
            <Item key="Pinterest">
              <Link
                themed={isActive}
                href=" https://co.pinterest.com/stitchzone/?amp_client_id=CLIENT_ID(_)&mweb_unauth_id=" target="_blank"
              >
                <img src={socialImagePinterest} width="24" height="24" />
                {/* <div style={{backgroundImage: "url('/StitchZone_files/social.jpeg')", width: "50px", height: "50px", backgroundPosition: "-205px -200px"}}></div> */}
              </Link>
            </Item>
            <Item key="Twitter">
              <Link
                themed={isActive}
                href="https://twitter.com/stitchzone?lang=en" target="_blank"
              >
                {/* <div style={{backgroundImage: "url('/StitchZone_files/social.jpeg')", width: "50px", height: "50px", backgroundPosition: "-22px -16px"}}></div> */}
                <img src={socialImageX} width="24" height="24" />
              </Link>
            </Item>
            {/* <Item key="Youtube">
              <Link themed={isActive} href="https://www.youtube.com/stitchzone">
                <img src={Youtube} width="24" height="24" />
                &nbsp;Youtube
              </Link>
            </Item>
            <Item key="Youtube1">
              <Link
                themed={isActive}
                href="https://www.linkedin.com/company/stitchzone"
              >
                <img src={Youtube} width="24" height="24" />
                &nbsp;LinkedIn
              </Link>
            </Item> */}
          </SocialList>
        </div>
        <div style={{ flexGrow: "1" }}>
          <ColTitle themed={isActive} style={{textTransform:"uppercase"}}>{`Our Company`}</ColTitle>
          <List style={{display:"flex", flexDirection:"column"}}>
            <Item key={`/about`}>
              <Link themed={isActive} href={`/about`} style={{textTransform:"uppercase"}} target="_blank">
                {`About Us`}
              </Link>
            </Item>
            <Item key={`/privacy`}>
              <Link themed={isActive} href={`/privacy`} style={{textTransform:"uppercase"}} target="_blank">
                {`Privacy`}
              </Link>
            </Item>
            <Item key={`/careers`}>
              <Link themed={isActive} href={`/careers`} style={{textTransform:"uppercase"}} target="_blank">
                {`Careers`}
              </Link>
            </Item>
            <Item key={`/ambassadorprogram`}>
              <Link themed={isActive} href={`/ambassadorprogram`} style={{textTransform:"uppercase"}} target="_blank">
                {`Ambassador Program`}
              </Link>
            </Item>
            <Item key={`/fundraise`}>
              <Link themed={isActive} href={`/fundraise`} style={{textTransform:"uppercase"}} target="_blank">
                {`Fundraise`}
              </Link>
            </Item>
          </List>
        </div>
        <div style={{ flexGrow: "1" }}>
          <ColTitle themed={isActive} style={{textTransform:"uppercase"}}>{`Orders & Returns`}</ColTitle>
          <List style={{display:"flex", flexDirection:"column"}}>
            <Item key={`/checkorderstatus`}>
              <Link themed={isActive} href={`/checkorderstatus`} style={{textTransform:"uppercase"}} target="_blank">
                {`Check Order Status`}
              </Link>
            </Item>
            <Item key={`/terms`}>
              <Link themed={isActive} href={`/terms`} style={{textTransform:"uppercase"}} target="_blank">
                {`Shipping & Pickup`}
              </Link>
            </Item>
            <Item key={`/terms`}>
              <Link themed={isActive} href={`/terms`} style={{textTransform:"uppercase"}} target="_blank">
                {`Refund & Returns`}
              </Link>
            </Item>
          </List>
        </div>
        <div style={{ flexGrow: "1" }}>
          <ColTitle themed={isActive} style={{textTransform:"uppercase"}}>{`Resources & Services`}</ColTitle>
          <List style={{display:"flex", flexDirection:"column"}}>
            <Item key={`/contact`}>
              <Link themed={isActive} href={`/contact`} style={{textTransform:"uppercase"}} target="_blank">
                {`Contact Us`}
              </Link>
            </Item>
            <Item key={`/faq`}>
              <Link themed={isActive} href={`/faq`} style={{textTransform:"uppercase"}} target="_blank">
                {`Faq`}
              </Link>
            </Item>
            <Item key={`/fonts`}>
              <Link themed={isActive} href={`/fonts`} style={{textTransform:"uppercase"}} target="_blank">
                {`Font & Colors`}
              </Link>
            </Item>
            <Item key={`/terms`}>
              <Link themed={isActive} href={`/terms`} style={{textTransform:"uppercase"}} target="_blank">
                {`Terms & Conditions`}
              </Link>
            </Item>
            <Item key={`/couponsanddeals`}>
              <Link themed={isActive} href={`/couponsanddeals`} style={{textTransform:"uppercase"}} target="_blank">
                {`Coupons & Deals`}
              </Link>
            </Item>
          </List>
        </div>
        <div style={{ flexGrow: "1" }}>
          <ColTitle themed={isActive} style={{textTransform:"uppercase"}}>{`Location: `}</ColTitle>
          <List style={{display:"flex", flexDirection:"column"}}>
            <Item key={`/checkorderstatus`}>
              <BrandMessage themed={isActive} style={{fontWeight:"bold"}}>{`4412 Technology Drive`}<div style={{marginBottom:"10px"}}></div>{`Fremont, CA 94538`}<div style={{marginBottom:"10px"}}></div>{`Phone: 510-818-1182`}</BrandMessage>
            </Item>
          </List>
        </div>
        {/*categories.map((category) => {
          return (
            <div key={category.headerLabel} style={{ flexGrow: "1" }}>
              <ColTitle themed={isActive}>{category.headerLabel}</ColTitle>
              <List>
                {category.children.map((subCategory) => {
                  return (
                    <Item key={subCategory.path}>
                      <Link themed={isActive} href={subCategory.path}>
                        {subCategory.label}
                      </Link>
                    </Item>
                  );
                })}
              </List>
            </div>
          );
        })*/}
        {/* <div key="Connect With Us" style={{ flexGrow: "1" }}>
          <ColTitle themed={isActive}>Connect With Us</ColTitle>
          <List>
            <Item key="facebook">
              <Link
                themed={isActive}
                href="https://m.facebook.com/profile.php?id=100063908976508"
              >
                <img src={Facebook} width="24" height="24" />
                &nbsp;Facebook
              </Link>
            </Item>
            <Item key="Instagram">
              <Link
                themed={isActive}
                href="https://www.instagram.com/stitchzone/?hl=en"
              >
                <img src={Instagram} width="24" height="24" />
                &nbsp;Instagram
              </Link>
            </Item>
            <Item key="Pinterest">
              <Link
                themed={isActive}
                href=" https://co.pinterest.com/stitchzone/?amp_client_id=CLIENT_ID(_)&mweb_unauth_id="
              >
                <img src={Pinterest} width="24" height="24" />
                &nbsp;Pinterest
              </Link>
            </Item>
            <Item key="Twitter">
              <Link
                themed={isActive}
                href="https://twitter.com/stitchzone?lang=en"
              >
                <img src={Twitter} width="24" height="24" />
                &nbsp;X/Twitter
              </Link>
            </Item>
            <Item key="Youtube">
              <Link themed={isActive} href="https://www.youtube.com/stitchzone">
                <img src={Youtube} width="24" height="24" />
                &nbsp;Youtube
              </Link>
            </Item>
            <Item key="Youtube1">
              <Link
                themed={isActive}
                href="https://www.linkedin.com/company/stitchzone"
              >
                <img src={Youtube} width="24" height="24" />
                &nbsp;LinkedIn
              </Link>
            </Item>
          </List>
        </div> */}
      </Container>
      <Copyright isActive={isActive} />
    </Wapper>
  );
};