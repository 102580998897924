import React from "react";
import styled, { css } from "styled-components";

/**
 * To consume this Table you must give it a data prop
 * data prop must be in the folowing structure:
 * [
 *  { key1: value11, key2: value21, ...}
 *  { key1: value12, key2: value22, ...}
 *  { key1: value13, key2: value23, ...}
 * ]
 */

type TableProps = {
  data: string[];
  fullWidth?: boolean;
};

export default ({ data, fullWidth }: TableProps) => (
  <TableMarkup titles={Object.keys(data[0]) || []} data={data} fullWidth={fullWidth?fullWidth:false} />
);

type TableMarkupProps = {
  titles: string[];
  data: string[];
  fullWidth: boolean;
};

const TableMarkup = ({ titles, data, fullWidth }: TableMarkupProps) => (
  <StyledTable fullWidth={fullWidth}>
    <caption></caption>
    <colgroup>
      <col />
      <col />
      <col />
    </colgroup>
    <thead>
      <tr>
        {titles &&
          titles.map((title: any, index: number) => (
            <th key={index}>{title}</th>
          ))}
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((item: any, index: number) => (
          <tr key={index}>
            {titles.map((title: any, index: number) => (
              <td key={index}>{item[title]}</td>
            ))}
          </tr>
        ))}
    </tbody>
    {/* <tfoot>
      <tr>
        {titles.map((title, index) => (
          <th key={index}>{title}</th>
        ))}
      </tr>
    </tfoot> */}
  </StyledTable>
);

const StyledTable = styled.table<{fullWidth:boolean}>`
  ${(props: any) => (props.fullWidth ? "width:100% !important;" : "")}
  caption-side: top;
  border: none;
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 5px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
    }
    :hover {
      background-color: lightgray;
    }
  }
  tbody tr td {
    text-align: center;
  }
  thead > tr {
    background-color: #c2c2c2;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const CenterV = css`
  display: flex;
  align-items: center;
`;

export const CenterH = css`
  display: flex;
  justify-content: center;
`;

export const CenterVH = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Center = styled.div<{ V: boolean; H: boolean }>`
  /* This is an example of a nested interpolation */
  ${(props: any) => (props.V ? CenterV : "")}
  ${(props: any) => (props.H ? CenterH : "")}
`;
