import React from "react";
import styled from "styled-components";
import { paddingL } from "../../constants";
import {
  FloaterIcon1,
  FloaterIcon2,
  FloaterIcon3,
  FloaterIcon4,
  FloaterIcon5,
} from "../../components";
import { DesignerViewStates } from "../../models";
import { Tooltip } from "@happeouikit/tooltip";

export const FloaterMenu = ({ changeViewState, designerViewState }: any) => {
  return (
    <Floater>
      <Tooltip
        id="float_menu_tooltip"
        content="Customization sections"
        background={"#000000"}
        text={"#FBFCFD"}
        place="left"
        effect="float"
      />
      <FloatingMenu data-tip data-for="float_menu_tooltip" id="preview_float_menu">
        <FloaterButton
          onClick={() => changeViewState(DesignerViewStates.ColorAndQuantity)}
          selected={designerViewState == DesignerViewStates.ColorAndQuantity?true:false}
        >
          <FloaterIcon1 />
        </FloaterButton>
        <FloaterButton
          onClick={() =>
            changeViewState(DesignerViewStates.AppliqueTypeSelector)
          }
          selected={designerViewState == DesignerViewStates.AppliqueTypeSelector?true:false}
        >
          <FloaterIcon2 />
        </FloaterButton>
        <FloaterButton
          onClick={() => changeViewState(DesignerViewStates.Applique)}
          selected={designerViewState == DesignerViewStates.Applique?true:false}
        >
          <FloaterIcon3 />
        </FloaterButton>
        <FloaterButton
          onClick={() => changeViewState(DesignerViewStates.EmboideryLocations)}
          selected={designerViewState == DesignerViewStates.EmboideryLocations?true:false}
        >
          <FloaterIcon4 />
        </FloaterButton>
        <FloaterButton 
          onClick={() => changeViewState(DesignerViewStates.Cart)}
          selected={designerViewState == DesignerViewStates.Cart?true:false}
        >
          <FloaterIcon5 />
        </FloaterButton>
      </FloatingMenu>
    </Floater>
  );
};

const Floater = styled.div`
  height: 100%;
  width: 65px;
  position: relative;
  background-color: transparent;
`;

const FloatingMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  right: -25%;
  transform: translate(0, -50%);
  width: 60px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
  border-color: transparent;
`;

const FloaterButton = styled.button<{ selected?:boolean }>`
  border: none;
  background-color: transparent;
  padding: ${paddingL} 0;
  svg {
    path {
      fill: lightgray;
      stroke: ${(props) => props.selected?`blue`:`gray`};
    }
  }
  
`;
