export enum ForegroundBackgroundSettings {
  "fill" = "fill",
  "stroke" = "stroke",
  "material" = "material",
}

export interface IMaterialFillStrokeForeGround {
  [ForegroundBackgroundSettings.fill]: string;
  [ForegroundBackgroundSettings.stroke]: string;
  [ForegroundBackgroundSettings.material]: string;
}

export interface IMaterialFillStrokeBackGround {
  [ForegroundBackgroundSettings.fill]: string;
  [ForegroundBackgroundSettings.stroke]: string;
  [ForegroundBackgroundSettings.material]: string;
}
