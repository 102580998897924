import React from "react";
import { Dispatch } from "redux";
import {
  IAppState,
  AnnouncementResponseDatum,
  AnnouncementResponse,
} from "../../models";
import { connect } from "react-redux";

import { announcementClient } from "../../ApiClients/Announcement";
import { Modal } from "@happeouikit/modals";

interface AnnouncementPageProps {
  getAnnouncement(): Promise<AnnouncementResponse | undefined>;
  announcement: any | AnnouncementResponseDatum;
}

interface AnnouncementPageState {
  isOpen: boolean;
}

export class AnnouncementPageComponent extends React.Component<
  AnnouncementPageProps,
  AnnouncementPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: this.props.announcement.announcement.isVisible,
    };
  }

  componentDidMount(): void {
    this.props
      .getAnnouncement()
      .then((annoucement) => {
        const isAlreadyOpenedOnce =
          sessionStorage.getItem("bannerClosed") === "true";
        if (
          annoucement &&
          annoucement.data &&
          annoucement.data.isVisible &&
          !isAlreadyOpenedOnce
        ) {
          this.setState({ isOpen: true });
        }
      })
      .catch(console.error);
  }

  confirm = () => {
    this.setState({ isOpen: false });
  };
  close = () => {
    sessionStorage.setItem("bannerClosed", "true");
    this.setState({ isOpen: false });
  };
  open = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const {
      props: { announcement },
    } = this;

    return (
      <>
        <Modal
          footer={false}
          isOpen={this.state.isOpen}
          width={"50%"}
          close={this.close}
          iconClose={true}
        >
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={announcement.announcement.file} />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    announcement: state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getAnnouncement: () => announcementClient.getAnnouncement(dispatch),
  };
};

export const AnnouncementPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementPageComponent);