import React from "react";
import Accordion from "./Accordion";
import { Container, Desc, Table, TermsHeader } from "./styled.component";
import { connect } from "react-redux";
import { spectificationClient } from "../../ApiClients";
import { IAppState, SpecificationResponseDatum } from "../../models";
import { Dispatch } from "redux";

interface TermsPageProps {
  getSpecification(): void;
  specification: any | SpecificationResponseDatum;
}

interface TermsPageState {
  active: number | null;
  isExpanded: boolean;
}

const data = [
  {
    id: 1,
    orderValue: "Below $20.00",
    standard: "$12.50",
    priority: "$22.50",
    express: "$30.50",
  },
  {
    id: 2,
    orderValue: "From $20.00 to $39.99",
    standard: "$15.50",
    priority: "$25.50",
    express: "$40.50",
  },
  {
    id: 3,
    orderValue: "From $40.00 to $69.99",
    standard: "$18.50",
    priority: "$28.50",
    express: "$50.50",
  },
  {
    id: 4,
    orderValue: "From $70.00 to $99.99",
    standard: "$21.50",
    priority: "$31.50",
    express: "$60.50",
  },
  {
    id: 6,
    orderValue: "From $100.00 to $149.99",
    standard: "$24.50",
    priority: "$34.50",
    express: "$70.50",
  },
  {
    id: 7,
    orderValue: "From $150.00 to $199.99",
    standard: "$27.50",
    priority: "$37.50",
    express: "$80.50",
  },
  {
    id: 8,
    orderValue: "From $200.00 to $249.99",
    standard: "$30.50",
    priority: "$40.50",
    express: "$90.50",
  },
  {
    id: 9,
    orderValue: "From $250.00 to $299.99",
    standard: "$33.50",
    priority: "$43.50",
    express: "$100.50",
  },
  {
    id: 10,
    orderValue: "From $300.00 to $349.99",
    standard: "$36.50",
    priority: "$46.50",
    express: "$110.50",
  },
  {
    id: 11,
    orderValue: "From $350.00 to $399.99",
    standard: "$39.50",
    priority: "$49.50",
    express: "$120.50",
  },
  {
    id: 12,
    orderValue: "From $400.00 to $449.99",
    standard: "$42.50",
    priority: "$52.50",
    express: "$130.50",
  },
  {
    id: 13,
    orderValue: "From $450.00 to $499.99",
    standard: "$45.50",
    priority: "$55.50",
    express: "$140.00",
  },
  {
    id: 14,
    orderValue: "From $500.00 to $599.99",
    standard: "$48.50",
    priority: "$58.50",
    express: "$150.00",
  },
  {
    id: 15,
    orderValue: "From $600.00 to $699.99",
    standard: "$51.50",
    priority: "$61.50",
    express: "$160.00",
  },
  {
    id: 16,
    orderValue: "From $700.00 to $799.99",
    standard: "$54.50",
    priority: "$64.50",
    express: "$170.00",
  },
  {
    id: 17,
    orderValue: "From $800.00 to $899.99",
    standard: "$57.50",
    priority: "$67.50",
    express: "$180.00",
  },
  {
    id: 18,
    orderValue: "From $900.00 and $999.99",
    standard: "$60.50",
    priority: "$70.50",
    express: "$190.00",
  },
  {
    id: 19,
    orderValue: "From $1000.00 and $1999.00",
    standard: "$70.50",
    priority: "$90.50",
    express: "$200.00",
  },
  {
    id: 20,
    orderValue: "From $2000.00 and $2999.00",
    standard: "$90.50",
    priority: "$120.50",
    express: "$300.00",
  },
  {
    id: 21,
    orderValue: "From $3000.00 and $3999.00",
    standard: "$100.50",
    priority: "$150.50",
    express: "$500.00",
  },
  {
    id: 22,
    orderValue: "From $4000.00 and $4999.00",
    standard: "$150.50",
    priority: "$200.50",
    express: "$700.00",
  },
  {
    id: 23,
    orderValue: "From $5000.00 and $5999.00",
    standard: "$200.50",
    priority: "$300.50",
    express: "$1000.00",
  },
];

export class TermsPageComponent extends React.Component<
  TermsPageProps,
  TermsPageState
> {
  constructor(props: TermsPageProps) {
    super(props);
    this.state = {
      active: 0,
      isExpanded: false,
    };
  }

  componentDidMount(): void {
    //
  }

  handleAccordion = (id: number, isExpanded: boolean) => {
    this.setState({ active: id, isExpanded: isExpanded });
  };
  render() {
    return (
      <Container>
        <TermsHeader>Policies for Stitchzone Orders</TermsHeader>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Shipping Rates"
          id={1}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Shipping rates are based on delivery within the United States.
            Prices may vary with weight and distance. International and RUSH
            shipments will be subject to additional charges.
          </Desc>
          <Table data={data} />
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Discounts and Specials"
          id={2}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Stitchzone offers many discounts and specials on individual and
            group orders. Contact us to find out how, 510.818.1182. Or visit our
            Special page.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Rush Service"
          id={3}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Rush service is available for most products depending upon when you
            place your order. To place a RUSH, you MUST contact StitchZone,
            510.818.1182. There is a fee for Rush orders.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Payment"
          id={4}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <>
            <Desc>
              Full payment on all orders must be received before any work can be
              done.We will accept a mailed personal or organizational check and
              money order. We do not ship C.O.D. Failure to pay for your order
              in a timely manner may result in additional rush charges or push
              back the delivery date of your order. Stitchzone is not
              responsible for any delay in order delivery caused by a delay in
              receiving payment. Paypal, Visa Master, Card Discover & American
              Express Online orders can be paid by PayPal. If you would like to
              order something from our website (www.stitchzone.com), but do not
              have a credit/debit card you can call to place your order or fax
              in an order form.
            </Desc>

            <Desc>
              <strong>Make Payments to:</strong>
            </Desc>
            <Desc>Segami Designs</Desc>
            <Desc>39120 Argonaut Way, Box 818</Desc>
            <Desc>Fremont, CA 94538</Desc>
            <Desc>
              <strong>Group Orders:</strong> If you are paying by check or money
              order for a group order, we will not accept multiple checks. Your
              organization must send one check or money order to pay for your
              order. It is the organization's responsibility to make sure we
              receive payment in a timely manner to avoid late fees.
            </Desc>
            <Desc>
              <strong>Returned Checks:</strong> You will be subject to a $50 per
              check processing fee for any returned checks in addition to the
              balance due on the order.
            </Desc>
          </>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Add-Ons and Reorders"
          id={5}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            You cannot add-on to your INDIVIDUAL order once it has been placed.
            You can place a second order that will be processed separately from
            your initial order. You may be able to add-on to a group order
            depending on the specifics of your order; however there may be
            additional charges for doing so. You may also be able to re-order
            more of a given item as long as you meet the minimum order
            requirement when applicable. For group screen printing orders, the
            minimum for re-orders is at least 24 pieces. We always recommend
            ordering a few extra items when you place group orders as add-ons
            and re-orders are not always possible. Please contact us for more
            information.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Backordered/Discontinued Items"
          id={6}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            We will make every attempt to contact you if the product(s) you
            ordered is on backorder. StitchZone reserves the right to substitute
            items of comparable value, without notice to the customer, to fill
            orders when necessary. Items that have been discontinued by the
            manufacture and have been replaced with a new model will be used
            when necessary. Manufacturers change model styles every so often.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Artwork"
          id={7}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            You can use one of our stock designs. Please contact us for
            instructions on submitting artwork. Once we have done your artwork
            we will post it on our web site. If necessary, our art department
            will make changes to artwork after it has been posted. The colors
            and sizing for artwork posted on our website is not exact. We size
            our artwork based on the item(s) ordered, the print location(s) and
            the image(s) being printed. If you want your artwork to be a
            specific size you must specify what size you want when you place
            your order or when you approve your artwork. You MUST approve your
            artwork before production can begin, contact us by calling
            510.818.1182 StitchZone is not responsible for any artwork mistakes
            on your items so please take the time to carefully check all
            artwork. Failure to approve your artwork in a timely manner may push
            back the delivery date of your order. Please keep in mind that we
            cannot order the blank items for your order or start production
            until the balance due on your order is paid. Please see the payment
            section above for more information. StitchZone is not responsible
            for any delay in order delivery caused by a delay in artwork
            approval. We reserve the right to display artwork submitted by
            customers in our portfolio for the purposes of advertising. We also
            reserve the right to refuse to create or print artwork that is
            unlawful or inappropriate. All designs are copyright Segami
            Designs/StitchZone.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Cancellation"
          id={8}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            All orders are custom-made based on the customer's instructions at
            the time of ordering and as such, the customer is responsible for
            any costs incurred by StitchZone up to the point of cancellation. If
            you cancel within 2 hours after placing your order, we will issue
            you a full refund. Any cancellations occurring after this time will
            be subject to a MINIMUM cancellation fee of 25% of the total order.
            INDIVIDUAL orders cannot be cancelled once we have ordered the
            garment(s) or product(s) for your order. For GROUP orders there is a
            MINIMUM cancellation fee of $75.00. Your organization will also be
            charged an additional restocking fee and processing fee if we have
            ordered products to fill your order. It may be possible to reduce
            the amount of items ordered without completely canceling an order.
            If this is possible, your organization will not be charged a
            cancellation fee, but you may be charged a restocking fee and a
            processing fee. The price per piece for the remaining items may also
            increase. Please contact us for more information. If your order has
            already been produced, it CANNOT be cancelled. To avoid cancellation
            charges, please be sure you have the approval of your organization
            before you place your order. Whether ordering online, over the phone
            or by fax, it is the customer's responsibility to make sure they
            have ordered correctly.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Garment Care"
          id={9}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            All clothing should be turned inside out and machine washed in cold
            water. DO NOT ADD BLEACH OR OTHER CHEMICALS. Wash all garments
            separately. Lay them flat to dry. THESE INSTRUCTIONS ARE TO REPLACE
            ANY WASHING INSTRUCTIONS INSIDE GARMENTS. Items with screen printing
            may form stress marks/tears over time. Washed or worn garments,
            including garments with this form of damage, cannot be replaced,
            returned for a refund or exchanged for another item.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Returns and Exchanges"
          id={10}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Since all orders are custom-made, there can be no returns unless the
            item(s) ordered is defective or the wrong item(s) is received. All
            orders are thoroughly examined and counted by at least two members
            of our production staff to ensure that item sizes (where applicable)
            and quantities are accurate. All items are also examined for defects
            prior to being shipped. StitchZone is not responsible for any
            problems with orders that have been opened or left unattended after
            they are delivered. For group screen printing orders it is not
            possible to re-print any missing or defective items unless the
            situation meets the criteria for a re-order (see above). If a
            problem does arise with your order, please contact a customer
            service representative within 72 hours of receiving your order.
            Please do not send any items back to us without calling first.
            Unauthorized returns may be refused and sent back to the customer.
            Items cannot be returned more than two weeks after receipt for any
            reason.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Licensing, Trademarked, or Patented Policies"
          id={11}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Since it is possible that a license and/or copyright may exist that
            StitchZone/Segami Designs is unaware of, it is necessary for
            StitchZone/Segami Designs to adopt the following licensing policy.
            The customer represents that all materials utilized by the seller in
            the distribution of the merchandise described in this web site
            (specifically including, but not limited to, the Greek
            Organization’s letters, crests, coat of arms, logos, school or
            university references, insignias, or other designs) will not cause
            or result in the violation of any law, judicial decision, rule,
            regulation, ordinance, licensing, franchise or similar agreements,
            informal or formal, oral or written, between customer and/or other
            relating to trademarks, service marks, copyrights, patents, etc. The
            customer further represents that he/she has the free, full, and
            unqualified right and authority to purchase and grants the seller
            the rights to sell all of the materials described on this web site.
            The customer acknowledges that the seller shall assume no liability
            for compliance or non-compliance with said laws, rules, agreements,
            etc. The seller hereby disclaims all such liability, if any. The
            customer agrees to indemnify and hold seller harmless from and
            against any loss, costs, fees, fines, licensing fees, penalties,
            damage, and/or assessment, or other expenses (including any attorney
            fees which may be incurred by seller) which may arise as a result of
            the sellers application, fabrication, and/or production of materials
            described on this web site. The products contained on the web site
            are for demonstration purposes only and do not indicate or imply
            that any such designs, logos, or names may be reproduced without
            proper authorization or permission. StitchZone/Segami Designs will
            utilize all reasonable means to ensure that products being sold from
            this website are produced by licensed manufacturers when and where
            appropriate.
          </Desc>
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Limit of Stitchzone's Responsibility"
          id={12}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Due to circumstances beyond our control we are not in control of
            color variances of materials. We can not guarantee continuity of
            exact shade, color, size, texture or construction of finished goods.
            There may be a +/- 5% variance with the size, placement and color of
            the finished product. You are responsible for providing StitchZone
            with accurate instructions for producing or re-producing your order
            correctly and in a timely manner. Do not assume we knows what you
            want and when you want it if he/she has not asked you. StitchZone
            will only be responsible for acting on those instructions given or
            sent to us that we actually receive. These instructions will affect
            the accuracy or timeliness of your order. We are not responsible for
            instructions that we do not receive, instructions we receive late or
            instructions that are not possible to follow. We are also not
            responsible for any incorrect instructions you may provide.
            StitchZone is not responsible for typographical errors in our
            catalog or on our website (www.stitchzone.com). We reserve the right
            to discontinue items and change the price per piece of items without
            notice. We also reserve the right to change these policies at any
            time without notice. Please contact a us if you have any questions
            regarding these policies. By placing an order with StitchZone, you
            have agreed to the terms and conditions described in these policies.
          </Desc>
          <Desc>
            <strong>PRIVACY POLICY</strong>
          </Desc>
          <Desc>
            StitchZone promises to protect the personal information given to us
            by visitors to our website and information included on our
            electronic mail lists.
          </Desc>
          <Desc>
            <strong>COLLECTION AND USE OF INFORMATION:</strong>
          </Desc>
          <Desc>
            StitchZone / Segami Designs analyzes our website logs to constantly
            improve the value of our website to our visitors. This includes page
            views, unique views, unique visitors, repeat visitors, frequency of
            visits, and peak-volume traffic periods. In our use of this service,
            we do not gather, request, record, require, collect, or track any
            Internet users' personally identifiable information.
          </Desc>
          <Desc>
            <strong>USE OF COOKIES:</strong>
          </Desc>
          <Desc>
            Cookies are small data files that are downloaded onto your computer
            when you visit our site and that automatically identify your browser
            to our server whenever you interact with our site. We do link the
            cookies to your personally identifiable information on our servers,
            but no personal information is stored in the cookies themselves. We
            use cookies to track your use of our site, and to authenticate users
            when signing in. We do not share cookies with any other site.{" "}
          </Desc>
          <Desc>
            <strong>DISCLOSURE OF INFORMATION:</strong>
          </Desc>
          <Desc>
            StitchZone does not sell, rent, loan, trade, or lease personal
            information collected on our website or included on our e-mail lists
            to any third party.
          </Desc>
          <Desc>
            <strong>DATA SECURITY:</strong>
          </Desc>
          <Desc>
            We take precautions to protect your data from loss, misuse,
            unauthorized access or disclosure, alteration, or destruction.
          </Desc>
          <Desc>
            <strong>ENFORCEMENT:</strong>
          </Desc>
          <Desc>
            If for any reason you believe that StitchZone has not adhered to
            these privacy principles, please notify us by e-mail at
            info@stitchzone.com, and we will endeavor to review and correct the
            problem promptly. Please use the words 'Privacy Policy' in the
            subject line.
          </Desc>
          <Desc>
            <strong>EXTERNAL LINKS:</strong>
          </Desc>
          <Desc>
            This website provides links to various websites that StitchZone does
            not control. When you click on one of these links, you will be
            transferred out of our website and connected to the website of the
            organization or company that you selected. Even if an affiliation
            exists between our website and a third party website, we exercise no
            control over linked sites. Each of these linked sites maintains its
            own independent privacy and data collection practices and
            procedures. If you visit a website that is linked to our site, you
            should consult that site's privacy policy before providing any
            personal information.
          </Desc>
          <Desc>
            <strong>INTERNATIONAL INFORMATION TRANSFERS:</strong>
          </Desc>
          <Desc>
            Any personally identifiable information that you provide to
            StitchZone / Segami Designs will be collected directly on servers
            located in the United States and maintained on servers located in
            the United States. If you are located outside the United States,
            this means that any information you provide will be transferred
            abroad. While StitchZone is committed to providing your information
            with the highest level of privacy protection, we believe you should
            know that the general level of protection for personal information
            in the United States may not be the same as that provided in other
            countries, including those that belong to the European Economic
            Area.
          </Desc>
          <Desc>
            <strong>TERMS AND MODIFICATIONS TO PRIVACY POLICY:</strong>
          </Desc>
          <Desc>
            By using this website, you signify your agreement to the terms of
            our privacy policy. If you do not agree with these terms, please do
            not disclose any personal information through this site. StitchZone
            may modify this privacy policy at any time at its discretion and
            modifications are effective upon being posted on this site. You are
            responsible for reviewing this privacy policy periodically to ensure
            that you are aware of any changes to it. If you have any questions
            or concerns regarding privacy on the StitchZone website, please
            contact us at info@stitchzone.com.
          </Desc>
        </Accordion>
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    specification: state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getSpecification: () => spectificationClient.getSpecification(dispatch),
  };
};

export const TermsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsPageComponent);
