export class AuthUtils {
  public static parseCookies(cookieStr: string = document.cookie) {
    const cookiesList = cookieStr.split(";");
    const cookiesMap: Record<string, string> = {};

    if (cookiesList && cookiesList.length) {
      cookiesList.forEach((currentCookie: string) => {
        const [cookieName, cookieValue] = currentCookie.trim().split("=");
        if (cookieName && cookieValue) cookiesMap[cookieName] = cookieValue;
      });
    }
    return cookiesMap;
  }
}
