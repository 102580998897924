import React from "react";
import {
  IFontStyle,
  PreviewEmbroidarySettingNames,
  PreviewSettingNames,
  UpdatePreviewParams,
} from "../../models";
import { Validation } from "../../Utils";
import {
  Embroidary,
  Embroidary_Postions,
  designWindowHeightValue,
  Embroidary_Text_Style
} from "../../constants";

const ALTERATION_VALUE = 40;

interface EmbroidaryPreviewProps {
  EmDesignAtrributes: any;
  selectedSide: any;
  fontOptions: IFontStyle[];
  parsePreviewParams(keys: UpdatePreviewParams[]): any;
  parseddAppliquePreviewParams: any;
  appliqueRef: any;
  svgRef: any;
  appliqueBound: any;
}

interface EmbroidaryPreviewState {
  appliqueBound: {};
  svgBound: {};
}

class RenderEmbroidaryPreview extends React.Component<
  EmbroidaryPreviewProps,
  EmbroidaryPreviewState
> {
  getStaticXValue = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = -imageSize * 2;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 2;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 2;
    } else if (imageSize >= 3 && imageSize < 6) {
      s = imageSize * 6;
    } else {
      s = imageSize * 8;
    }
    return s;
  };

  getStaticYValue = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = imageSize * 12;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 14;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 15;
    } else if (imageSize >= 3 && imageSize < 6) {
      s = imageSize * 18;
    } else {
      s = imageSize * 19;
    }
    return s;
  };

  getStaticYBottomValue = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = imageSize * 6;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 4;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 2;
    } else if (imageSize >= 3 && imageSize < 6) {
      s = imageSize * 2;
    } else {
      s = imageSize * 0.6;
    }
    return s;
  };

  getXLetter(key: string, currentEm: any) {
    const { appliqueRef, svgRef } = this.props;
    const { x: appliqueViewX, bottom: appliqueViewBottom } =
      appliqueRef.getBoundingClientRect();
    const { x: svgX } = svgRef.getBoundingClientRect();
    let imageSize =
      Number(currentEm.imageSize.split("in")[0]) * designWindowHeightValue;
    switch (key) {
      case PreviewEmbroidarySettingNames.Design_1:
      case PreviewEmbroidarySettingNames.Design_2:
        const location = currentEm[PreviewSettingNames.embroidaryLocation];
        if (
          appliqueViewX === svgX &&
          currentEm.embroidaryLocation === "Right Arm"
        ) {
          if (imageSize <= 2) {
            return appliqueViewX - 80;
          }
          return appliqueViewX - 100;
        }
        if (
          appliqueViewX === svgX &&
          currentEm.embroidaryLocation === "Left Arm"
        ) {
          if (imageSize <= 2) {
            return appliqueViewX - 40;
          }
          return appliqueViewX - 60;
        }

        return svgX - this.getStaticXValue(imageSize) + ALTERATION_VALUE;
      // if (location === Embroidary.aboveGreekLetter) {
      //   return appliqueViewX - svgX - (this.getStaticValue(imageSize));
      // } else if (location === Embroidary.overGreekLetter) {
      //   return appliqueViewX - svgX - (this.getStaticValue(imageSize));
      // } else if (location === Embroidary.underGreekLetter) {
      //   return appliqueViewX - svgX - (this.getStaticValue(imageSize));
      // } else {
      //   return Embroidary_Postions.CenterDesignX;
      // }
      default:
        return Embroidary_Postions.CenterY;
    }
  }
  getYLetter(key: string, currentEm: any) {
    const { appliqueRef, svgRef, parseddAppliquePreviewParams } = this.props;
    const { y: appliqueViewY, bottom: appliqueViewBottom } =
      appliqueRef.getBoundingClientRect();
    const { y: svgY, bottom: svgBottom } = svgRef.getBoundingClientRect();
    let imageSize =
      Number(currentEm.imageSize.split("in")[0]) * designWindowHeightValue;
    switch (key) {
      case PreviewEmbroidarySettingNames.Above:
        //return appliqueViewY - svgY - ALTERATION_VALUE;
        /*console.log(
          "parseddAppliquePreviewParams[PreviewSettingNames.letterSize] : ",
          parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
        );*/
        switch (parseddAppliquePreviewParams[PreviewSettingNames.letterSize]) {
          case "0.4in":
            return svgY + ALTERATION_VALUE - 32;
            break;
          case "0.6in":
            return svgY + ALTERATION_VALUE - 40;
            break;
          case "0.8in":
            return svgY + ALTERATION_VALUE - 45;
            break;
          default:
            return svgY + ALTERATION_VALUE;
            break;
        }

      case PreviewEmbroidarySettingNames.Design_1:
      case PreviewEmbroidarySettingNames.Design_2:
        const location = currentEm[PreviewSettingNames.embroidaryLocation];
        if (location === Embroidary.aboveGreekLetter) {
          //return appliqueViewY - svgY - this.getStaticYValue(imageSize);
          let getStaticYValue =
            this.getStaticYValue(imageSize) - ALTERATION_VALUE;
          //return svgY - this.getStaticYValue(imageSize);
          switch (
            parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
          ) {
            case "0.4in":
              return svgY + getStaticYValue - 32;
              break;
            case "0.6in":
              return svgY + getStaticYValue - 40;
              break;
            case "0.8in":
              return svgY + getStaticYValue - 45;
              break;
            default:
              return svgY + getStaticYValue;
              break;
          }
        } else if (location === Embroidary.overGreekLetter) {
          return Embroidary_Postions.CenterY;
        } else if (location === Embroidary.underGreekLetter) {
          /* return (
            appliqueViewBottom - svgY - this.getStaticYBottomValue(imageSize)
          ); */
          //return svgY - this.getStaticYBottomValue(imageSize);
          let getStaticYBottomValue =
            this.getStaticYBottomValue(imageSize) + ALTERATION_VALUE + 10;
          switch (
            parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
          ) {
            case "0.4in":
              return svgY + getStaticYBottomValue + 40;
              break;
            case "0.6in":
              return svgY + getStaticYBottomValue + 47;
              break;
            case "0.8in":
              return svgY + getStaticYBottomValue + 57;
              break;
            default:
              return svgY + getStaticYBottomValue;
              break;
          }
        } else {
          return Embroidary_Postions.CenterDesignY;
        }
      case PreviewEmbroidarySettingNames.Under:
        //return appliqueViewBottom - svgY + ALTERATION_VALUE;
        /*return (
          svgBottom - appliqueViewBottom - appliqueViewY + ALTERATION_VALUE
        );*/
        switch (parseddAppliquePreviewParams[PreviewSettingNames.letterSize]) {
          case "0.4in":
            return svgY + ALTERATION_VALUE + 40;
            break;
          case "0.6in":
            return svgY + ALTERATION_VALUE + 47;
            break;
          case "0.8in":
            return svgY + ALTERATION_VALUE + 57;
            break;
          default:
            return svgY + ALTERATION_VALUE;
            break;
        }
      case PreviewEmbroidarySettingNames.Over:
        return Embroidary_Postions.CenterY;
      case PreviewEmbroidarySettingNames.Top:
        return Embroidary_Postions.TopY;
      case PreviewEmbroidarySettingNames.Bottom:
        return Embroidary_Postions.BottomY;
      case PreviewEmbroidarySettingNames.Right:
        return Embroidary_Postions.RightY;
      case PreviewEmbroidarySettingNames.Left:
        return Embroidary_Postions.LeftY;
      case PreviewEmbroidarySettingNames.Hood_Forward:
        return Embroidary_Postions.HoodY;
      case PreviewEmbroidarySettingNames.Hood_Backward:
        return Embroidary_Postions.HoodY; 
      default:
        return Embroidary_Postions.CenterY;
    }
  }

  getPreviewSvgTextProperties(value: string) {
    const urlDefs: string[] = [];

    if (Validation.isValidUrl(value)) {
      const patternImgUrl = value.replace("http:","https:");
      urlDefs.push(patternImgUrl);
    }

    return { urlDefs };
  }

  displayMultilineTextSVG(part:{
    x: number,
    y: number,
    fontSize: number,
    lineHeight: number,
    style: any,
    flipStyle: any,
    fontFamily: string,
    text: string
  }){
    var drawx=part.x||0;
    var drawy=part.y||0;
    var fontSize=part.fontSize||14; 
    var lineHeight=part.lineHeight||1.25; 
    var style=part.style||""; 
    var flipStyle=part.flipStyle||"";
    var fontFamily=part.fontFamily||"Arial"; 
    //var text=part.text.split('\n').map(function(a,i){ return <tspan x={drawx} y={(drawy+fontSize*lineHeight+i*fontSize*lineHeight)}>{a}</tspan> }).join('\n');
    //console.log("part.text : ", part.text);
    /* check if text style */
    let text: any = "";
    if(part.text.indexOf(Embroidary_Text_Style.flipped) !== -1){
      /* flipped */
      text = part.text.replace(Embroidary_Text_Style.flipped, "");
      //console.log("displayFlippedTextWithFO text : ", text);
      text = this.displayFlippedTextWithHTML(text);
      /* return this.displayFlippedTextSVG({
        x: drawx,
        y: drawy,
        style: flipStyle,
        text: text,
        fontSize: fontSize,
        lineHeight: lineHeight
      }); */
      /* console.log("displayFlippedTextSVG : ", text.outerHTML); */
      return <foreignObject x={drawx} y={drawy} style={flipStyle}  dangerouslySetInnerHTML={{ __html:text}}></foreignObject>;
    } else {
      /* normal */
      text=part.text.split('\n').map(function(a,i){ return '<tspan x="'+drawx+'" y="'+(drawy+fontSize*lineHeight+i*fontSize*lineHeight)+'">'+a+'</tspan>' }).join('\n');
      /* wrap with text tag and return */
      return <text x={drawx} y={drawy} style={style}  dangerouslySetInnerHTML={{ __html:text}}></text>; /* fontFamily={fontFamily} fontSize={fontSize} */
    }
  }

  displayFlippedTextWithHTML(text: string){
    const outputElement = document.createElement('div'); //document.getElementById('output');
    outputElement.classList.add("flipped-text");
    outputElement.innerHTML = '';

    const textArr = text.split(" ").reverse();
    textArr.forEach((word: any, i:number) => {
      const flippedText = word.split("").reverse().join("");
      for (const char of flippedText) {
        const span = document.createElement('span');
        span.textContent = char;
        outputElement.appendChild(span);
      }

      // add space character
      const span = document.createElement('span');
      span.innerHTML = '&#160;';
      outputElement.appendChild(span);
    });

    /* convert html object to string and return */
    return outputElement.outerHTML;
  }

  displayFlippedTextSVG(part :{
    x: number,
    y: number,
    style: any,
    text: string,
    fontSize: number,
    lineHeight: number
  }) {
    var drawx=part.x||0;
    var drawy=part.y||0;
    var fontSize=part.fontSize||14; 
    var lineHeight=part.lineHeight||1.25; 
    var flipStyle=part.style||"";
    var text=part.text||"";

    /* const outputElement = document.createElement('div'); //document.getElementById('output');
    outputElement.classList.add("flipped-text");
    outputElement.innerHTML = ''; */
    
    const wordArr:Array<any> = [];
    const textArr = text.split(" ").reverse();
    let c = 0;
    textArr.forEach((word: any, i:number) => {
      const flippedText = word.split("").reverse().join("");
      const charArr:Array<any> = [];
      
      for (const char of flippedText) {
          /* const span = document.createElement('span');
          span.textContent = char;
          outputElement.appendChild(<tspan x={drawx} y={drawy} style={style}  dangerouslySetInnerHTML={{ __html:text}}></tspan>); */
          charArr.push(`<tspan x="${(drawy+fontSize*lineHeight+c*fontSize*lineHeight)}" y="${drawy}" style="display: inline-block; transform: rotate(180deg); transform-origin: top center;" >${char}</tspan>`);
          c++;
      }
      // add space character
      /* const span = document.createElement('span');
          span.innerHTML = '&#160;';
          outputElement.appendChild(span); */
      if(i < textArr.length-1){
          charArr.push(`<tspan x="${(drawy+fontSize*lineHeight+c*fontSize*lineHeight)}" y="${drawy}" style="display: inline-block; transform: rotate(180deg); transform-origin: top center;" >&#160;</tspan>`);
      }

      wordArr.push(charArr.join(""));
    });
    /* console.log("wordArr : ", wordArr); */
    return <foreignObject x={drawx} y={drawy}><svg x={drawx} y={drawy}><text x={drawx} y={drawy} style={flipStyle}  dangerouslySetInnerHTML={{ __html:wordArr.join("")}}></text></svg></foreignObject>;
    /* convert html object to string and return */
    //return outputElement.outerHTML;
  }

  render() {
    /* console.log(" EMBROIDARY RENDER :::::::::::::: "); */
    const { EmDesignAtrributes, selectedSide } = this.props;
    const renderEmbroidary =
      EmDesignAtrributes.length > 0 &&
      EmDesignAtrributes[0].map((i: any) => {
        const keys = Object.keys(i);
        const currentEm = this.props.parsePreviewParams(i[keys[0]]);
        /* console.log(currentEm, "currentEmcurrentEmcurrentEm"); */

        const design = currentEm[PreviewSettingNames.design];
        /*console.log(
          currentEm[PreviewSettingNames.letterSize],
          "currentEm[PreviewSettingNames.letterSize]"
        );*/

        const emFontFamilyValue = this.props.fontOptions.find(
          ({ _id }) =>
            _id === (currentEm as any)[PreviewSettingNames.fontFamily]
        );

        const emFontFamilyCssValue = emFontFamilyValue
          ? emFontFamilyValue.name
          : "inherit";

        return (
          <>
            <style type="text/css">
            {`
              .flipped-text {
                  /*font-size: 2rem;*/
                  /*line-height: 1;*/ /* Ensures no extra spacing between lines */
                  display: flex;
                  align-items: flex-start; /* Aligns items at the top */
                  
              }
              .flipped-text span {
                  display: inline-block;
                  transform: rotate(180deg); /* Rotates each character */
                  /*transform-origin: top center;*/ /* Rotates around the top */
                  /*margin-top:26%;*/
              }
            `}
            </style>
            <g
              style={{
                overflow: "hidden",
                textAnchor: "middle",
                fontWeight: "bold",
              }}
            >
              {this.displayMultilineTextSVG({
                x: 125,
                y: 300,
                fontFamily: emFontFamilyCssValue,
                fontSize: currentEm[PreviewSettingNames.letterSize]*96 || 0.15*96,
                lineHeight: 1.25,
                style: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "transparent",
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                },
                flipStyle: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "transparent",
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "flex-start",
                  /* marginTop: "26%", */
                  width: "300px",
                  height: "50px"
                },
                text: currentEm?.contentText
              })}
              {/* <text
                x={`30%`}
                y={this.getYLetter(keys[0], currentEm)}
                style={{
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "transparent",
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                }}
              >
                {currentEm?.contentText}
              </text> */}
              {this.displayMultilineTextSVG({
                x: 125,
                y: 300,
                fontFamily: emFontFamilyCssValue,
                fontSize: currentEm[PreviewSettingNames.letterSize]*96 || 0.15*96,
                lineHeight: 1.25,
                style: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "#000",
                  stroke: "transparent",
                  strokeWidth: 1,
                  fontFamily: emFontFamilyCssValue,
                },
                flipStyle: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "transparent",
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "flex-start",
                  /* marginTop: "26%", */
                  width: "300px",
                  height: "50px"
                },
                text: currentEm?.contentText
              })}
              {/* <text
                x={`30%`}
                y={this.getYLetter(keys[0], currentEm)}
                style={{
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "#000",
                  stroke: "transparent",
                  strokeWidth: 1,
                  fontFamily: emFontFamilyCssValue,
                }}
              >
                {currentEm.contentText}
              </text> */}
              {design && (
                <image
                  href={design.replace("http:","https:")}
                  x={this.getXLetter(keys[0], currentEm)}
                  y={this.getYLetter(keys[0], currentEm)}
                  width={currentEm[PreviewSettingNames.imageSize] || "0.2in"}
                  height={currentEm[PreviewSettingNames.imageSize] || "0.2in"}
                />
              )}
            </g>
          </>
        );
      });
    return <>{renderEmbroidary}</>;
  }
}

export default RenderEmbroidaryPreview;
