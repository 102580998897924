import styled from "styled-components";
import { primaryColor } from "../constants";

const Title = styled.h1`
  color: ${primaryColor};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 30px;
  padding: 0 30px 0 30px;
`;

export { Title };
