import React from "react";
import { Title } from "../../components/Title";
import { Container } from "./styled.component";

export class SpecialsPage extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Title>Specials Offers and Deals</Title>
        <Container>Content in here</Container>
      </>
    );
  }
}
