import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getHeader } from "../actions";
import { HeaderResponse } from "../models";

class HeaderClient extends BaseApiClient {
    public async getHeader(dispatch: Dispatch) {
        try {
            const headerData = await this.get<HeaderResponse>("public/category");
            dispatch(getHeader(headerData));
        } catch (error) {
            console.log(error);
        }
    }
}

export const headerClient = new HeaderClient();
