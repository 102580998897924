import { AnyAction } from "redux";
import { UPDATE_EMBROIDARY_VIEW } from "../actions";

interface IEmbroidaryReducer {}

const initialState: IEmbroidaryReducer = {
  embroidaryView: {
    front: {},
    back: {},
    arm_right: {},
    arm_left: {},
    cuff_right: {},
    cuff_left: {},
    collar: {},
    collar_flipper_up: {},
    hood_forward: {},
    hood_backward: {}
  },
};

export function embroidary(
  state: IEmbroidaryReducer = initialState,
  action: AnyAction
): IEmbroidaryReducer {
  switch (action.type) {
    case UPDATE_EMBROIDARY_VIEW:
      return {
        ...state,
        embroidaryView: action.embroidaryView,
      };
    default:
      return state;
  }
}
