import React from "react";
import { Title } from "../../components/Title";
import { Container, List, Item } from "./styled.component";
import { Img } from "../Specification/styled.component";

import DesignImage from "../../images/designs.jpg";
import FontImage from "../../images/fonts.jpg";
import ColorImage from "../../images/colors.jpg";
import SizeImage from "../../images/size.jpg";

export class SpecificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Title>Specifications</Title>
        <Container>
          <List>
            <Item href="/design">
              <Img src={DesignImage} />
            </Item>
          </List>

          <List>
            <Item href="/fonts">
              <Img src={FontImage} />
            </Item>
          </List>

          <List>
            <Item href="/colors">
              <Img src={ColorImage} />
            </Item>
          </List>
          <List>
            <Item href="/size-and-location">
              <Img src={SizeImage} />
            </Item>
          </List>
        </Container>
      </>
    );
  }
}
