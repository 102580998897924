import React, {
  useState,
  useEffect,
  ChangeEvent,
  ReactComponentElement,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormContainer,
  LeftSection,
  FormGroup,
  FormGroupColumn,
  RightSection,
  ButtonAlpha,
  AccordionContainer,
} from "./styled.component";

import { RadioButton, Textarea } from "@happeouikit/form-elements";
import { Input, Dropdown, Checkbox } from "@happeouikit/form-elements";

import { profileClient } from "../../ApiClients/ProfileClient";
import { toast } from "@happeouikit/toast";

import { TextZeta, BodyUI } from "@happeouikit/typography";
import { AddressForm } from "./address.form";
import { ProfileResponseDatum, IProfileAddressState } from "../../models";
import Accordion from "./Accordion";

import Table, { Center } from "../../components/Table";
import Edit from "../../components/svgIcons/Edit";
import Delete from "../../components/svgIcons/Delete";
import {maskingPhone} from "../../Utils/common";

interface ProfileFormProps {}

export interface IProfileAddressDisplay {
  fullName: string;
  mobileNumber: string;
  addressLine1: string;
  addressLine2: string;
  landmark: string;
  city: string;
  state: string;
  pinCode: string;
  action: React.ReactNode | string;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  /* const [isShippingModalVisible, setIsShippingModalVisible] = useState(false); */
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);

  const [selectedAccordian, setSelectedAccordian] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState<any>(false);
  const [isDelete, setIsDelete] = useState(false);

  const dispatch = useDispatch();

  const profileData: any = useSelector<ProfileResponseDatum>(
    (state: any) => state.profile
  );

  const profileBillingTableData: any =
    useSelector<Array<IProfileAddressDisplay>>((state: any) =>
      state.profileAddress.billingAddresses.map(
        (billInfo: IProfileAddressState) => ({
          [` Full Name `]: billInfo.fullName,
          [` Phone Number `]: maskingPhone(billInfo.mobileNumber),
          [` Extension `]: billInfo.extension,
          [` Address Line 1 `]: billInfo.addressLine1,
          [` Address Line 2 `]: billInfo.addressLine2,
          /* landmark: billInfo.landmark, */
          [` City `]: billInfo.city,
          [` State `]: billInfo.state,
          [` ZIP Code `]: billInfo.pinCode,
          [` Organization `]: billInfo.orgName,
          [` School `]: billInfo.schoolName,
          Action: (
            <>
              <a
                style={{ color: "Blue" }}
                onClick={() => editAddress(billInfo)}
              >
                <Edit />
              </a>
              &nbsp; &nbsp;
              <a
                style={{ color: "Red" }}
                onClick={() => deleteAddress(billInfo)}
              >
                <Delete />
              </a>
            </>
          ),
        })
      )
    ) || [];

  /* const profileShippingTableData: any =
    useSelector<Array<IProfileAddressDisplay>>((state: any) =>
      state.profileAddress.shippingAddresses.map(
        (shipInfo: IProfileAddressState) => ({
          fullName: shipInfo.fullName,
          mobileNumber: shipInfo.mobileNumber,
          addressLine1: shipInfo.addressLine1,
          addressLine2: shipInfo.addressLine2,
          // landmark: shipInfo.landmark,
          city: shipInfo.city,
          state: shipInfo.state,
          ZipCode: shipInfo.pinCode,
          action: (
            <>
              <a
                style={{ color: "Blue" }}
                onClick={() => editAddress(shipInfo)}
              >
                Edit
              </a>
              &nbsp; &nbsp;
              <a
                style={{ color: "Red" }}
                onClick={() => deleteAddress(shipInfo)}
              >
                Delete
              </a>
            </>
          ),
        })
      )
    ) || []; */

  console.log("profileBillingTableData : ", profileBillingTableData);
  /* console.log("profileShippingTableData : ", profileShippingTableData); */

  useEffect(() => {
    profileClient.getProfile(dispatch);
  }, []);

  useEffect(() => {
    if (profileData._id) {
      console.log("profileData : ", profileData);
      setName(profileData.name);
      setEmail(profileData.email);
      setPhone(profileData.phone);

      /* fetch billing addresses */
      profileClient.getProfileAddresses(dispatch, profileData._id, "");

      /* fetch shipping addresses */
      /* profileClient.getProfileAddresses(dispatch, profileData._id, "shipping"); */
    }
  }, [profileData]);

  useEffect(() => {
    console.log("profileBillingTableData : ", profileBillingTableData);
    /* console.log("profileShippingTableData : ", profileShippingTableData); */
  }, [profileBillingTableData]); /* , profileShippingTableData */

  const fetchAddresses = () => {
    setIsDelete(false);
    setIsBillingModalVisible(false);
    /* setIsShippingModalVisible(false); */
    /* fetch billing addresses */
    profileClient.getProfileAddresses(dispatch, profileData._id, "");

    /* fetch shipping addresses */
    /* profileClient.getProfileAddresses(dispatch, profileData._id, "shipping"); */
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.value;
    setName(name);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    let email = e.target.value.trim();
    setEmail(email);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value;
    setPhone(phone);
  };

  const handleProfileDataSave = async () => {
    let formData = new FormData();
    formData.append("_id", profileData._id);
    formData.append("name", name.trim());
    formData.append("email", email);
    formData.append("phone", (phone + "").trim());

    const profileDataUpdateResponse = await profileClient.updateProfile(
      /* {
        _id: profileData._id,
        name: name,
        email: email,
        phone: phone + "",
      } */ formData,
      dispatch
    );

    if (profileDataUpdateResponse?.message != "") {
      toast.success({
        message: `${profileDataUpdateResponse?.message}`,
        delay: 4000,
      });
    }
  };

  /* const handleAddShipping = () => {
    setSelectedAddress(false);
    setIsShippingModalVisible(!isShippingModalVisible);
  }; */

  const handleAddBilling = () => {
    setSelectedAddress(false);
    setIsBillingModalVisible(!isBillingModalVisible);
  };

  const handleAccordion = (id: number) => {
    setSelectedAccordian(id);
  };

  const editAddress = (addr: IProfileAddressState) => {
    setSelectedAddress(addr);
    /* if (addr.addressType == "billing") {
      setIsBillingModalVisible(!isBillingModalVisible);
    } else {
      setIsShippingModalVisible(!isShippingModalVisible);
    } */

    setIsBillingModalVisible(!isBillingModalVisible);
  };

  const deleteAddress = (addr: IProfileAddressState) => {
    setSelectedAddress(addr);
    setIsDelete(true);
    setIsBillingModalVisible(false);
    /* setIsShippingModalVisible(false); */
  };

  return (
    <>
      <FormGroup>
        <AccordionContainer>
          <Accordion
            handleToggle={handleAccordion}
            header="Profile Information"
            id={1}
            active={selectedAccordian}
          >
            <FormContainer>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="Name"
                  autoComplete="off"
                  value={name}
                  onChange={handleNameChange}
                  label="Full Name"
                />
              </FormGroup>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="Email"
                  autoComplete="off"
                  value={email}
                  onChange={handleEmailChange}
                  label="Email"
                />
              </FormGroup>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="Phone"
                  autoComplete="off"
                  value={phone}
                  onChange={handlePhoneChange}
                  label="Phone"
                />
              </FormGroup>

              <FormGroup>
                <ButtonAlpha
                  style={{ height: "fit-content", margin: "50%" }}
                  onClick={handleProfileDataSave}
                >
                  Save
                </ButtonAlpha>
              </FormGroup>
            </FormContainer>
          </Accordion>
          <Accordion
            handleToggle={handleAccordion}
            header="Address Information"
            id={2}
            active={selectedAccordian}
          >
            <FormContainer>
              <FormGroup style={{width:"100%"}}>
                <ButtonAlpha
                  style={{ height: "fit-content", margin: "0.1%" }}
                  onClick={handleAddBilling}
                >
                  Add Address
                </ButtonAlpha>
              </FormGroup>
            </FormContainer>
              {profileBillingTableData.length > 0 && (
                <>
                  <Center V={true} H={true} style={{width:"100%"}}>
                    <Table data={profileBillingTableData} fullWidth={true} />
                  </Center>
                </>
              )}
            
            <AddressForm
              title="Address Info"
              isOpen={isBillingModalVisible}
              close={() => handleAddBilling()}
              onDone={fetchAddresses}
              addressType="billing"
              profileData={profileData}
              selectedAddress={selectedAddress}
              isDelete={isDelete}
            />
          </Accordion>
          {/* <Accordion
            handleToggle={handleAccordion}
            header="Shipping Address Information"
            id={3}
            active={selectedAccordian}
          >
            <FormGroup>
              <ButtonAlpha
                style={{ height: "fit-content", margin: "0.1%" }}
                onClick={handleAddShipping}
              >
                Add Shipping
              </ButtonAlpha>
            </FormGroup>

            <FormContainer>
              {profileShippingTableData.length > 0 && (
                <>
                  <Center V={true} H={true}>
                    <Table data={profileShippingTableData} />
                  </Center>
                </>
              )}
            </FormContainer>
            <AddressForm
              title="Shipping Info"
              isOpen={isShippingModalVisible}
              close={() => handleAddShipping()}
              onDone={fetchAddresses}
              addressType="shipping"
              profileData={profileData}
              selectedAddress={selectedAddress}
              isDelete={isDelete}
            />
          </Accordion> */}
        </AccordionContainer>
      </FormGroup>
    </>
  );
};
