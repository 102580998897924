import styled from "styled-components";
import { marginXL, paddingM, primaryColor } from "../../constants";

const Container = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0%;
`;

const SubContainer = styled.div`
  position: absolute;
  top: 190%;
  left: 50%;
  width: 100%;
  z-index: 99;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListDropdown = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  padding: 20px;
  display: none;
  background: #fff;
  border-radius: 4px;
`;

const LinkList = styled.ul`
  display: flex;
  list-style: none;
  margin-right: 10px;
`;

const List = styled.li`
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid ${primaryColor};
  color: ${primaryColor};
  position: relative;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid white;
  }

  &:hover ${ListDropdown} {
    display: block;
  }
`;

const Logo = styled.img`
  height: 40px;
  margin: 4px 10px;
`;

const DropDown = styled.div`
  display: none;
  background: white;
  position: absolute;
  left: 0;
  top: 41px;
  z-index: 9;
  padding: 10px;
  box-shadow: 0px 0px 8px 0px #828282f2;\

  &:hover {
    text-decoration: underline !important;
  }
`;

const ProfileDropDown = styled.div`
  display: none;
  background: white;
  position: absolute;
  left: 0;
  top: 41px;
  z-index: 9;
  padding: 10px;
  box-shadow: 0px 0px 8px 0px #828282f2;
`;

const CategoryLinks = styled.a<{ color: string, selected?:boolean }>`
  position: relative;
  color: ${(props) => props.selected?`#000`:props.color};
  background-color: ${(props) => props.selected?`#FFFFFF`:`none`};
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 20px;
  padding: 14px 6px;
  display: inline-block;
  

  &:hover:not(:last-child) {
    color: #000;
    background-color: #FFFFFF;
  }

  &:hover ${ProfileDropDown} {
    display: block;
    left: -175%;
  }
  &:hover ${DropDown} {
    display: block;
    left: -15%;
  }
  &:after {
    /* content: "|"; */
    position: absolute;
    right: -10px;
  }
`;

const HeaderActions = styled.div`
  margin-left: ${marginXL};
`;

const SmallHeader = styled.div`
  background: white;
  color: ${primaryColor};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 10px;
`;

const BtnPrimary = styled.a`
  margin-right: 10px;
  background-color: ${primaryColor};
  color: white;
  padding: 13px 24px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
`;

const BtnSecondary = styled.a`
  margin-right: 10px;
  background-color: white;
  color: ${primaryColor};
  padding: 13px 24px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
`;

const ExploreBtn = styled.button`
  color: ${primaryColor};

  background-color: #b0b0b057;
  width: 250px;
  border: none;
  padding: 10px 20px;
`;

export {
  Container,
  Logo,
  HeaderActions,
  CategoryLinks,
  SmallHeader,
  DropDown,
  ProfileDropDown,
  SubContainer,
  LinkList,
  BtnPrimary,
  BtnSecondary,
  List,
  ListDropdown,
  ExploreBtn,
};
