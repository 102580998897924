import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getOrder } from "../actions";
import { OrderResponse } from "../models";

class OrderClient extends BaseApiClient {
  async getOrder1() {
    try {
      const orderData = await this.get<OrderResponse>("order/get");
      return orderData.data;
    } catch (error) {
      console.log(error);
    }
  }
  public async getOrder(dispatch: Dispatch) {
    try {
      const orderData = await this.get<OrderResponse>("order/get");
      dispatch(getOrder(orderData));
    } catch (error) {
      console.log(error);
    }
  }

  public async cancelOrder(data: any) {
    try {
      return await this.putFormData<OrderResponse>(
        "order/update",
        this.generateFormData({ _id: data._id, orderCancelled: true })
      );
    } catch (error) {
      return {
        data: null,
        error: "Something went wrong",
      };
    }
  }
}

export const orderClient = new OrderClient();
