import { Endpoints } from "../constants";
import {
  ILoginRequestParams,
  ISignUpRequestParams,
  ISignupResponse,
  LoginResponse,
} from "../models";
import { BaseApiClient } from "./BaseApiClient";

class AuthClient extends BaseApiClient {
  loginUser(loginParams: ILoginRequestParams) {
    const url = Endpoints.userLogin;
    return this.postJson<LoginResponse>(url, loginParams);
  }

  signup(signUpParams: ISignUpRequestParams) {
    const url = Endpoints.userSignup;
    return this.postJson<ISignupResponse>(url, signUpParams);
  }

  fetchUser() {
    // const url = this.constructUrl();
  }
}

export const authClient = new AuthClient();
