import React from "react";
import { Title } from "../../components/Title";
import { ImageList, Item, Image, Desc } from "./styled.component";
import { IGallery } from "../../models/interfaces/Gallery";
import { ImageModal } from "./Image.modal";
import { Link } from "react-router-dom";

interface GalleryPageProps {}
interface GalleryPageState {
  url: string;
  isModalOpen: boolean;
  images: IGallery[] | [];
}

export class GalleryPage extends React.Component<
  GalleryPageProps,
  GalleryPageState
> {
  constructor(props: GalleryPageProps) {
    super(props);
    this.state = {
      images: [],
      isModalOpen: false,
      url: "",
    };
  }

  componentDidMount(): void {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYWM4MDIzMTNlODQ3OTM5YjVlMWQ1MCIsImlhdCI6MTY3MzE1ODAwMSwiZXhwIjoxNjczMTg2ODAxfQ.vpzGNpcArIWco2jvFR0hFPrgX1r2YY5qmbWEgUZ_ReY"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    //fetch("http://localhost:8000/public/productlist", requestOptions)
    //fetch("http://13.232.137.152:3000/public/productlist", requestOptions)
    fetch("https://favorsbythebay.com/apis/public/productlist", requestOptions) 
      .then((response) => response.json())
      .then((result) => {
        const galleryImages = result.data.map((e: any) => {
          return {
            categoryId: e?.categoryId,
            URL: e?.colors[0]?.images?.front,
            name: e?.item,
          };
        });
        this.setState({ images: galleryImages });
      })
      .catch((error) => console.log("error", error));
  }

  handleClose = () => {
    this.setState({ isModalOpen: false, url: "" });
  };

  handleClick = (imgURL: string) => {
    this.setState({ isModalOpen: true, url: imgURL });
  };
  render() {
    return (
      <>
        <Title>This is Gallery Page</Title>
        <ImageList>
          {this.state.images.map((img, index) => {
            return (
              <Item key={img.URL + index}>
                <Image
                  onClick={() => this.handleClick(img.URL)}
                  src={img.URL}
                  alt="Image Not Available"
                />
                <Desc>
                  <Link to={`/catalog/${img.categoryId}`}>{img.name}</Link>
                </Desc>
              </Item>
            );
          })}
        </ImageList>
        {this.state.isModalOpen && (
          <ImageModal URL={this.state.url} onClose={this.handleClose} />
        )}
      </>
    );
  }
}
