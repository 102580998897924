import React, { ChangeEvent } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  CartSizeQuantity,
  CustomizedProduct,
  IAppState,
  ICartState,
} from "../../models";
import { landingClient } from "../../ApiClients/LandingClient";
import { cartClient } from "../../ApiClients/CartClient";
import styled from "styled-components";
import {
  TextBeta,
  TextTheta,
  TinyText,
  TextZeta,
} from "@happeouikit/typography";
import {
  marginL,
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
} from "../../constants";
import { DummyCartItem, ICartItem } from "../../models/interfaces/CartItems";

import CartInfo from "./CartInfo";
import CartCTA from "./CartCTA";
import { IOneProductV2 } from "../../models/ApiResponses/IGetProductResponseV2";
import { toast } from "@happeouikit/toast";

import { Input, Dropdown, Checkbox } from "@happeouikit/form-elements";
import { addresses, shipping_preferences } from "../../data/addresses";

import { profileClient } from "../../ApiClients/ProfileClient";
import {
  addPriceToCart,
  updatePriceToCart,
  removePriceToCart,
} from "../../actions";

interface ShippingProps {
  onNext(): void;
  onBack(): void;
  selectedProduct: IOneProductV2;
}

interface ShippingPropsFromRedux {
  cart: ICartState;
  getCart(): void;
  profileAddresses: any;
  getBillingAddress(userId: any, addressType: any): void;
  getShippingAddress(userId: any, addressType: any): void;
  shippingRates: any;
  getShippingRates(totalPrice: any): void;
  addPriceToCart(data: any): void;
  removePriceToCart(data: any): void;
  addPriceToCart(data: any): void;
  updatePriceToCart(data: any): void;
  removePriceToCart(data: any): void;
}

interface ShippingState {
  // items: DummyCartItem;
  cartData: any;
  profileAddresses: any;
  shippingRates: any;
  openAccordionIndices: Record<number, boolean>;
  shippingAddressSelected: any;
  billingAddressSelected: any;
  shippingPreferenceSelected: any;
  isPickupSignatureNeeded: boolean;
  discountCode: string;
  referrel: string;
  needByDate: string;
  textMeAfterQuoteSent: boolean;
}

export class ShippingComponent extends React.Component<
  ShippingProps & ShippingPropsFromRedux,
  ShippingState
> {
  constructor(props: ShippingProps & ShippingPropsFromRedux) {
    super(props);
    this.state = {
      cartData: [],
      profileAddresses: [],
      shippingRates: {},
      // items: DummyCartItem.data.customizedProducts,
      openAccordionIndices: {},
      shippingAddressSelected: false,
      billingAddressSelected: false,
      shippingPreferenceSelected: false,
      isPickupSignatureNeeded: false,
      discountCode: "",
      referrel: "",
      needByDate: "",
      textMeAfterQuoteSent: false,
    };
    //this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  componentDidMount(): void {
    cartClient.getCart1().then((cartData) => {
      console.log(cartData, "cartData");
      this.setState(
        {
          cartData: cartData,
        },
        () => {
          let totalAmount = 0;

          let cartData = this.state.cartData;
          let cartItemTotalPriceArr =
            cartData.length > 0
              ? cartData.map((i: any) => {
                  return i.totalPrice;
                })
              : [];

          totalAmount = cartItemTotalPriceArr.reduce(
            (acc: any, item: any) => (acc += item),
            totalAmount
          );

          /* update cart totalPrice */
          this.props.updatePriceToCart({
            totalPrice: totalAmount,
          });

          /* get shipping rates api */
          this.props.getShippingRates(totalAmount);
        }
      );
    });
    // this.props.getCart();

    let userDetails: any = sessionStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);

    /* fetch billing addresses */
    this.props.getBillingAddress(userDetails.userId, "billing");

    /* fetch shipping addresses */
    this.props.getShippingAddress(userDetails.userId, "shipping");
  }

  /*toggleAccordion(index: number) {
    const { openAccordionIndices } = this.state;
    if (openAccordionIndices[index]) {
      openAccordionIndices[index] = false;
    } else {
      openAccordionIndices[index] = true;
    }

    this.setState({ openAccordionIndices: { ...openAccordionIndices } });
  }*/

  /*private getPrice(item: CartSizeQuantity[]): string {
    console.log("🚀 ~ file: Shipping.tsx:69 ~ getPrice ~ item:", item);

    return (Math.random() * 10).toFixed();
  }*/

  /*addQuotation = () => {
    const { cartData } = this.state;
    let cartIds =
      cartData.length > 0
        ? cartData.map((i: any) => {
            return i._id;
          })
        : [];
    const data = {
      cartIds: cartIds,
      totalPrice: 400,
      currency: "$",
    };
    cartClient.addQuotation(data).then((re) => {
      if (!re.error) {
        toast.error({ message: "Quotaion has been sent!" });
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };*/

  setShippingAddress = (address: any) => {
    this.setState({
      shippingAddressSelected: address,
    });
  };

  setBillingAddress = (address: any) => {
    this.setState({
      billingAddressSelected: address,
    });
    /* if (!this.state.shippingAddressSelected) {
      this.setState({
        shippingAddressSelected: address,
      });
    } */
  };

  setShippingPreferences = (preference: any) => {
    if (this.state.shippingPreferenceSelected) {
      this.props.removePriceToCart({
        price: +(this.state.shippingPreferenceSelected as any)["cost"],
      });
    }

    this.setState({ shippingPreferenceSelected: preference }, () => {
      this.props.addPriceToCart({
        price: +(this.state.shippingPreferenceSelected as any)["cost"],
      });

      if (
        this.state.shippingPreferenceSelected.name != "Customer Pick-up" &&
        this.state.isPickupSignatureNeeded
      ) {
        this.setState(
          { isPickupSignatureNeeded: !this.state.isPickupSignatureNeeded },
          () => {
            this.props.removePriceToCart({
              price: 2,
            });
          }
        );
      }
    });
  };

  handleIsPickupSignatureNeeded = () => {
    if (this.state.isPickupSignatureNeeded) {
      this.props.removePriceToCart({
        price: 2,
      });
    }

    this.setState(
      {
        isPickupSignatureNeeded: !this.state.isPickupSignatureNeeded,
      },
      () => {
        if (
          this.state.shippingPreferenceSelected.name == "Customer Pick-up" &&
          this.state.isPickupSignatureNeeded
        ) {
          this.props.addPriceToCart({
            price: 2,
          });
        }
      }
    );
  };

  setDiscountCode = (e: ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    this.setState({ discountCode: text });
  };

  applyDiscountCode = () => {
    if (this.state.discountCode != "") {
      this.setState({ discountCode: "" });
      toast.success({
        message: `Discount has been appliyed!`,
        delay: 2000,
      });
    } else {
      toast.error({
        message: `Invalid discount code.`,
        delay: 2000,
      });
    }
  };

  setReferrel = (e: ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    this.setState({ referrel: text });
  };

  setNeedByDate = (e: ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value;
    this.setState({ needByDate: text });
  };

  setTextMeAfterQuoteSent = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ textMeAfterQuoteSent: !this.state.textMeAfterQuoteSent });
  };

  addOrder = () => { console.log(this.props.cart);
    const {
      cartData,
      shippingAddressSelected,
      billingAddressSelected,
      shippingPreferenceSelected,
      needByDate,
      discountCode,
      referrel,
      textMeAfterQuoteSent,
      isPickupSignatureNeeded,
    } = this.state;
    let userDetails: any = sessionStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    //console.log("cartData : ", this.props.cart.cartData.totalPrice);
    let totalAmount = cartData.reduce((total:any, prdct:any) => total + parseInt(prdct.totalPrice), 0); //cartData.totalPrice;
    let totalOrderQty = cartData.reduce((total:any, prdct:any) => total + parseInt(prdct.totalQuantity), 0);

    const data = {
      userId: userDetails.userId,
      productList: cartData,
      shippingAddressId: shippingAddressSelected.value,
      billingAddressId: billingAddressSelected.value,
      //shippingRulesId: this.props.shippingRates._id,
      additionalData: {
        shippingType: shippingPreferenceSelected.name,
        shippingPrice: shippingPreferenceSelected.cost,
        needByDate: needByDate,
        discountCode: discountCode,
        referrel: referrel,
        textMeAfterQuoteSent: textMeAfterQuoteSent,
        isPickupSignatureNeeded:
          shippingPreferenceSelected.name == "Customer Pick-up"
            ? isPickupSignatureNeeded
            : false,
        netOrderAmount: totalAmount,
        totalOrderDiscount: 0.00,
        totalOrderQty: totalOrderQty
      },
      totalAmount: totalAmount + "",
      currency: "$",
    };

    console.log("orderData : ", data);
    //return false;
    cartClient.addOrder(data).then((re) => {
      if (!re.error) {
        toast.success({ message: "Order has been created!" });
        /* call cart list api */
        cartClient.getCart1().then((cartData) => {
          console.log(cartData, "cartDatacartDatacartData");
          this.setState({
            cartData: cartData,
          });
        });
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };

  addShipping = () => {};

  render(): React.ReactNode {
    const {
      openAccordionIndices,
      cartData,
      shippingAddressSelected,
      billingAddressSelected,
      shippingPreferenceSelected,
      isPickupSignatureNeeded,
      discountCode,
      referrel,
      needByDate,
      textMeAfterQuoteSent,
    } = this.state;

    console.log("profileAddresses : ", this.props.profileAddresses);
    console.log("billingAddressSelected : ", billingAddressSelected);
    console.log("shippingRates : ", this.props.shippingRates);

    let shippingPreferences = [];
    for (const key in this.props.shippingRates) {
      if (Object.prototype.hasOwnProperty.call(this.props.shippingRates, key)) {
        if (["standard", "express", "priority"].indexOf(key) !== -1) {
          shippingPreferences.push({
            name: key + " shipping",
            cost: this.props.shippingRates[key],
            currency: "$",
          });
        }
      }
    }
    /* Customer Pick-up */
    shippingPreferences.push({
      name: "Customer Pick-up",
      cost: "0",
      currency: "$",
    });
    console.log(shippingPreferences);

    const { selectedProduct } = this.props;

    //console.log("shippingAddressSelected,billingAddressSelected,shippingPreferenceSelected : ", shippingAddressSelected , billingAddressSelected, shippingPreferenceSelected);

    return (
      <>
        <MainContainer>
          <Wrapper>
            <SettingHeader>
              <TextZeta className="bold">Shipping Information</TextZeta>
              <TotalPrice>
                {/* <TextZeta>Estimate Total:</TextZeta> */}
              </TotalPrice>
            </SettingHeader>
          </Wrapper>
        </MainContainer>
        <Wrapper>
          <SecondaryContainer>
            <TextZeta className="bold">Billing Address</TextZeta>
            <FormContainer>
              <LeftSection>
                <FormGroup>
                  <Dropdown
                    label=""
                    options={this.props.profileAddresses.billingAddresses?.map(
                      ({
                        _id,
                        addressLine1,
                        addressLine2,
                        landmark,
                        city,
                        state,
                        pinCode,
                      }: any) => ({
                        label: `${addressLine1} ${addressLine2} ${landmark}, ${city}, ${state} ${pinCode}`,
                        value: _id,
                        street: `${addressLine1}${addressLine2}${landmark}`,
                        city,
                        state,
                        pinCode,
                      })
                    )}
                    onChange={this.setBillingAddress}
                    value={billingAddressSelected}
                  />
                </FormGroup>
              </LeftSection>
              {/*<RightSection>
                <FormGroupColumn>
                  <div
                    style={{
                      paddingTop: "8%",
                    }}
                  >
                    <TextZeta
                      className="bold"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Street{" "}
                      <div
                        style={{
                          fontSize: "12px",
                          textDecoration: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        {billingAddressSelected.street}
                      </div>
                    </TextZeta>
                  </div>
                  <div
                    style={{
                      paddingTop: "8%",
                    }}
                  >
                    <TextZeta
                      className="bold"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      City{" "}
                      <div
                        style={{
                          fontSize: "12px",
                          textDecoration: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        {billingAddressSelected.city}
                      </div>
                    </TextZeta>
                  </div>
                  <div
                    style={{
                      paddingTop: "8%",
                    }}
                  >
                    <TextZeta
                      className="bold"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Zip{" "}
                      <div
                        style={{
                          fontSize: "12px",
                          textDecoration: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        {billingAddressSelected.zip}
                      </div>
                    </TextZeta>
                  </div>
                </FormGroupColumn>
              </RightSection> */}
            </FormContainer>
            <TextZeta className="bold">Shipping Address</TextZeta>
            <FormContainer>
              <LeftSection>
                <FormGroup>
                  <Dropdown
                    label=""
                    options={this.props.profileAddresses.billingAddresses?.map(
                      ({
                        _id,
                        addressLine1,
                        addressLine2,
                        landmark,
                        city,
                        state,
                        pinCode,
                      }: any) => ({
                        label: `${addressLine1} ${addressLine2} ${landmark}, ${city}, ${state} ${pinCode}`,
                        value: _id,
                        street: `${addressLine1}${addressLine2}${landmark}`,
                        city,
                        state,
                        pinCode,
                      })
                    )}
                    onChange={this.setShippingAddress}
                    value={shippingAddressSelected}
                  />
                </FormGroup>
              </LeftSection>
              {/*<RightSection>
                <FormGroupColumn>
                  <div
                    style={{
                      paddingTop: "8%",
                    }}
                  >
                    <TextZeta
                      className="bold"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Street{" "}
                      <div
                        style={{
                          fontSize: "12px",
                          textDecoration: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        {shippingAddressSelected.street}
                      </div>
                    </TextZeta>
                  </div>
                  <div
                    style={{
                      paddingTop: "8%",
                    }}
                  >
                    <TextZeta
                      className="bold"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      City{" "}
                      <div
                        style={{
                          fontSize: "12px",
                          textDecoration: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        {shippingAddressSelected.city}
                      </div>
                    </TextZeta>
                  </div>
                  <div
                    style={{
                      paddingTop: "8%",
                    }}
                  >
                    <TextZeta
                      className="bold"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Zip{" "}
                      <div
                        style={{
                          fontSize: "12px",
                          textDecoration: "none",
                          paddingLeft: "10px",
                        }}
                      >
                        {shippingAddressSelected.zip}
                      </div>
                    </TextZeta>
                  </div>
                </FormGroupColumn>
              </RightSection>*/}
            </FormContainer>
            <TextZeta className="bold">Shipping Preferences</TextZeta>
            <FormContainer>
              <FormGroup>
                <Dropdown
                  label=""
                  options={shippingPreferences?.map(
                    ({ name, cost, currency }) => ({
                      label: `${name} (${currency}${cost})`,
                      value: name,
                      name,
                      cost,
                      currency,
                    })
                  )}
                  onChange={this.setShippingPreferences}
                  value={shippingPreferenceSelected}
                />
              </FormGroup>
            </FormContainer>
            {shippingPreferenceSelected.name === "Customer Pick-up" && (
              <FormContainer>
                <div style={{ flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <TextZeta>
                      Store Pick-Up Hours: Mon - Fri from 10:00 AM - 1:00 PM
                    </TextZeta>
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextZeta>
                      $15 fee will apply if pick-up is at another time.
                    </TextZeta>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      value={`1`}
                      checked={isPickupSignatureNeeded}
                      onChange={this.handleIsPickupSignatureNeeded}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: "1.5rem",
                        flexFlow: "column wrap",
                      }}
                    >
                      <span> ADD Signature Confirmation $2.00 </span>
                      <span style={{ fontSize: ".8rem" }}>
                        (If not selected StitchZone will not be held responsible
                        if the package is stolen or lost after delivery.)
                      </span>
                    </div>
                  </div>
                </div>
              </FormContainer>
            )}
            <div style={{ marginTop: "2%" }}>
              <TextZeta className="bold">
                Do you need this order by a specific date? If yes, please enter
                your need by date. Additional fees will incur if shorter than
                our Standard Production time. (see below)
              </TextZeta>
              <FormContainer>
                <FormGroup>
                  <Input
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    value={needByDate}
                    onChange={this.setNeedByDate}
                    label="Need by Date:"
                  />
                </FormGroup>
                <FormGroup>
                  <TextZeta
                    style={{ height: "fit-content", margin: "12% 0% 0% 5%" }}
                  >
                    (A.S.A.P. is not a date)
                  </TextZeta>
                </FormGroup>
              </FormContainer>
            </div>
            <div style={{ marginTop: "2%" }}>
              <TextZeta className="bold">Promotional Code</TextZeta>
              <FormContainer>
                <FormGroup>
                  <Input
                    placeholder="Enter discount code"
                    autoComplete="off"
                    value={discountCode}
                    onChange={this.setDiscountCode}
                    label="Do you have a code?"
                  />
                </FormGroup>
                <ButtonAlpha
                  style={{ height: "fit-content", margin: "3.3%" }}
                  onClick={this.applyDiscountCode}
                >
                  Apply
                </ButtonAlpha>
              </FormContainer>
            </div>
            <div style={{ marginTop: "2%" }}>
              <TextZeta className="bold">Referrel</TextZeta>
              <FormContainer>
                <FormGroup>
                  <Input
                    placeholder=""
                    autoComplete="off"
                    value={referrel}
                    onChange={this.setReferrel}
                    label="How did you hear about us?"
                  />
                </FormGroup>
              </FormContainer>
              <FormContainer>
                <FormGroup>
                  <div style={{ flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        value={`1`}
                        checked={textMeAfterQuoteSent}
                        onChange={this.setTextMeAfterQuoteSent}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "1.5rem",
                          flexFlow: "column wrap",
                        }}
                      >
                        <span> TEXT ME WHEN QUOTE IS SENT. </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextZeta>
                        I know that the email may go to my SPAM folder.
                      </TextZeta>
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextZeta>
                        I agree to receive SMS based on my data plan.
                      </TextZeta>
                    </div>
                  </div>
                </FormGroup>
              </FormContainer>
            </div>
            {/*cartData.length > 0 ? (
              cartData.map((item: any, index: number) => {
                const isOpen = !!openAccordionIndices[index];
                return (
                  <CartItemContianer key={index} style={{ margin: "10px 0" }}>
                    <CartItemHeader
                      isOpen={isOpen}
                      onClick={() => this.toggleAccordion(index)}
                    >
                      <CartItemHeaderTitle>
                        {item.productId.name}
                      </CartItemHeaderTitle>
                      <CartItemHeaderPrice>
                        <span>{item.totalQuantity}</span>
                        <span> | </span>
                        <span>
                          Order Estimate: {item.currency} {item.totalPrice}
                        </span>
                      </CartItemHeaderPrice>
                    </CartItemHeader>
                    {isOpen && <CartInfo cartDataItem={item} />}
                  </CartItemContianer>
                );
              })
            ) : (
              <h2>No Records</h2>
            )*/}
            <CartCTA
              cartItemCount={cartData.length}
              addOrder={this.addOrder}
              addShipping={() => {}}
              hasShippingInfo={true}
            />
          </SecondaryContainer>
        </Wrapper>
      </>
    );
  }
}

export const MainContainer = styled.div`
  /* min-height: 500px; */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  display: flex;
  flex-direction: column;
`;

const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const SecondaryContainer = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
`;

const Wrapper = styled.div``;

const TotalPrice = styled.div`
  p {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: bold;
  }
`;

const CartItemContianer = styled.div``;

const CartItemHeader = styled.div<{ isOpen: boolean }>`
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (!props.isOpen ? "#ECECEC" : "#8585855e")};
  display: flex;
  padding: 8px 12px;
  border-radius: 5px;
  min-height: 60px;
  cursor: pointer;
`;

const CartItemHeaderTitle = styled.div`
  width: 40%;
`;

const CartItemHeaderPrice = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
`;

const ModifierContainer = styled.div`
  margin-top: ${marginM};
`;

const ModifierBreakDownTable = styled.table`
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: ${marginM};
`;

const DropDownWrapper = styled.div`
  width: 27%;
  margin: 0 10px 10px 0;
`;

const FormContainer = styled.div`
  display: flex;
`;

const LeftSection = styled.section`
  flex: 1;
  margin-right: ${marginL};
`;

const RightSection = styled.section`
  flex: 1;
`;

const FormGroupColumn = styled.div`
  margin-bottom: ${marginM};
  display: flex;
  justify-content: space-between;
`;

const ButtonAlpha = styled.button`
  all: unset;
  text-align: left;
  font: normal normal 600 16px/20px Source Sans Pro;
  letter-spacing: 0.51px;
  color: #ffffff;
  background: #142f8f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
`;

const mapStateToProps = (state: IAppState) => {
  return {
    cart: state.cart,
    profileAddresses: state.profileAddress,
    shippingRates: state.shippingRates,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCart: () => cartClient.getCart(dispatch),
    getBillingAddress: (userId: any, addressType: any) => {
      profileClient.getProfileAddresses(dispatch, userId, addressType);
    },
    getShippingAddress: (userId: any, addressType: any) => {
      profileClient.getProfileAddresses(dispatch, userId, addressType);
    },
    getShippingRates: (totalPrice: any) => {
      profileClient.getShippingRates(dispatch, totalPrice);
    },
    addPriceToCart: (data: any) => dispatch(addPriceToCart(data)),
    updatePriceToCart: (data: any) => dispatch(updatePriceToCart(data)),
    removePriceToCart: (data: any) => dispatch(removePriceToCart(data)),
  };
};

export const Shipping = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingComponent);
