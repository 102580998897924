import styled from "styled-components";
import { Input, Checkbox, Textarea } from "@happeouikit/form-elements";
import { primaryColor, themeWhite } from "../../constants";

const Container = styled.div`
  padding: 0 30px;
`;
const FormContainer = styled.div`
  width: 40%;
`;
const StyledButton = styled.button`
  color: ${themeWhite};
  background-color: ${primaryColor};
  border-radius: 25px;
  width: 48%;
  padding: 5px;
  margin-top: 10px;
  height: 40px;
  font-size: 14px;
`;

const StyeledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;
const StyledInput = styled(Input)`
  margin-bottom: 20px;
  border: 2px solid #ffffff;
  border-radius: 24px;
  opacity: 1;
  color: #424447;
  background-color: #ffffff87;
  height: 40px;
  box-shadow: 0px 0px 10px #00000029;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTextarea = styled(Textarea)`
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 1;
  color: #424447;
  background-color: #ffffff87;
`;
const StyledSignup = styled.h2`
  color: blue;
`;

export {
  Container,
  StyledInput,
  StyledButton,
  ButtonContainer,
  StyledTextarea,
  FormContainer,
  StyeledCheckbox,
  StyledSignup,
};
