import styled from "styled-components";
import { Input } from "@happeouikit/form-elements";
import { primaryColor, secondaryColor, themeWhite } from "../../constants";
import Pattern from "../../images/pattern2.png";

const Section = styled.section`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-image: url(${Pattern});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
  position: relative;
`;

const Logo = styled.img`
  height: 40px;
`;

const Desc = styled.p`
  position: absolute;
  bottom: 40px;
  left: 40px;
  color: ${themeWhite};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 50vw;
`;

const FormContainer = styled.div`
  background: transparent linear-gradient(137deg, #ffffff8a 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #00000029;
  border-radius: 25px;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translate(0, -50%);
  padding: 40px 40px 0 40px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  color: ${primaryColor};
  font-weight: bold;
  margin-bottom: 5px;
`;

const SubTitle = styled.h3`
  margin: 0;
  padding-bottom: 50px;
  font-size: 20px;
  font-weight: bold;
  color: ${secondaryColor};
`;

const SignUpCta = styled.p`
  margin: 40px 0 10px 0;
  color: ${secondaryColor};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: 200;
  display: inline-block;
  width: 100%;
`;
const SignUpLink = styled.a`
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
`;
const StyledButton = styled.button`
  color: ${themeWhite};
  background-color: ${primaryColor};
  border-radius: 25px;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  height: 40px;
  font-size: 14px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  border: 2px solid #ffffff;
  border-radius: 24px;
  box-shadow: 0 0 10 #00000029;
  opacity: 1;
  color: #424447;
  background-color: #ffffff87;
  height: 40px;
  box-shadow: 0px 0px 10px #00000029;
`;
export {
  Section,
  Logo,
  Desc,
  FormContainer,
  Title,
  SubTitle,
  SignUpCta,
  SignUpLink,
  StyledButton,
  StyledInput,
};
