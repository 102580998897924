import { AnyAction } from "redux";
import { GET_SPECIFICATION } from "../actions";
import { SpecificationResponseDatum } from "../models";

let initialState: SpecificationResponseDatum = {
  letterSizes: [],
  embroidaries: [],
  fontStyles: [],
  color: [],
  embroidaryDesigns: [],
};

export function specification(
  state: SpecificationResponseDatum = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case GET_SPECIFICATION:
      initialState = action.data.data;
      return { ...initialState, isFetching: false };
    default:
      return state;
  }
}
