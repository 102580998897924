import React from "react";
import styled from "styled-components";
import { ImagesV2 } from "../../models/ApiResponses/IGetProductResponseV2";
import { Tooltip } from "@happeouikit/tooltip";

interface SidePrevieweProps {
  images: ImagesV2;
  onSelect(url: string, side: string): void;
  applicableEmbroidaries: string[];
  onlyFrontEnabled: boolean;
  selectedSide:string;
}
interface SidePreviewState {}

export default class SidePreview extends React.Component<
  SidePrevieweProps,
  SidePreviewState
> {
  constructor(props: SidePrevieweProps) {
    super(props);
    this.state = {};

    /* console.log("this.props.images -> hood_forward : ", this.props.images.hood_forward); */
  }

  handleAccordion = (id: String) => {
    this.setState({ active: id });
  };

  render() {
    const { onlyFrontEnabled, selectedSide } = this.props;
    return (
      <Container>
        <ImgCover color={this.props.selectedSide === "front" ? "blue":"white"}>
          <Img
            src={this.props.images.front}
            onClick={() =>
              this.props.onSelect(this.props.images.front, "front")
            }
          />
        </ImgCover>
        <ImgCover color={this.props.selectedSide === "back" ? "blue":"white"}>
          <Img
            src={this.props.images.back}
            onClick={() => {
              this.props.onSelect(this.props.images.back, "back");
            }}
          />
        </ImgCover>
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "arm_left")) !== -1 && this.props.images.arm_left && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "arm_left" ? "blue":"white"}>
          <Img
            src={this.props.images.arm_left}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.arm_left, "arm_left");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "arm_right")) !== -1 && this.props.images.arm_right && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "arm_right" ? "blue":"white"}>
          <Img
            src={this.props.images.arm_right}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.arm_right, "arm_right");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "cuff_left")) !== -1 && this.props.images.cuff_left && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "cuff_left" ? "blue":"white"}>
          <Img
            src={this.props.images.cuff_left}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.cuff_left, "cuff_left");
            }}
          />
          </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "cuff_right")) !== -1 && this.props.images.cuff_right && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "cuff_right" ? "blue":"white"}>
          <Img
            src={this.props.images.cuff_right}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.cuff_right, "cuff_right");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "on_collar")) !== -1 && this.props.images.on_collar && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "on_collar" ? "blue":"white"}>
          <Img
            src={this.props.images.on_collar}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.on_collar, "on_collar");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "collar_flipped_up")) !== -1 && this.props.images.collar_flipped_up && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "collar_flipped_up" ? "blue":"white"}>
          <Img
            src={this.props.images.collar_flipped_up}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.collar_flipped_up, "collar_flipped_up");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "hood")) !== -1 && this.props.images.hood && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "hood" ? "blue":"white"}>
          <Img
            src={this.props.images.hood}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.hood, "hood");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "hood_forward")) !== -1 && this.props.images.hood_forward && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "hood_forward" ? "blue":"white"}>
          <Img
            src={this.props.images.hood_forward}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.hood_forward, "hood_forward");
            }}
          />
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "hood_backward")) !== -1 && this.props.images.hood_backward && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "hood_backward" ? "blue":"white"}>
          <Img
            src={this.props.images.hood_backward}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.hood_backward, "hood_backward");
            }}
          />
        </ImgCover>}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  margin-right: 10px;
  /*padding-top: 7%;*/
  padding-bottom: 3%;
  margin-top: 0%;
  height: 70vh;
  overflow-y: scroll;
`;

const ImgCover = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.disable {
    cursor: not-allowed;
    filter: opacity(0.4);
  }

  width: 100px;
  height: 100px;
  border: 2px solid ${(props) => props.color || "white"};
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: fill;
`;
