export const ADD_PRICE_CART = "ADD_PRICE_CART";
export const UPDATE_PRICE_CART = "UPDATE_PRICE_CART";
export const REMOVE_PRICE_CART = "REMOVE_PRICE_CART";
export const ADD_COLOR_TO_CART = "ADD_COLOR_TO_CART";
export const UPDATE_QUANTITY_TO_CART = "UPDATE_QUANTITY_TO_CART";
export const UPDATE_APPLIQUE_TO_CART = "UPDATE_APPLIQUE_TO_CART";
export const UPDATE_EMBROIDART_TO_CART = "UPDATE_EMBROIDART_TO_CART";
export const UPDATE_PRODUCT_ID_TO_CART = "UPDATE_PRODUCT_ID_TO_CART";
export const UPDATE_PREVIEW_IMAGES_TO_CART = "UPDATE_PREVIEW_IMAGES_TO_CART";

export const UPDATE_SIZE_QTY_PRICE_CART = "UPDATE_SIZE_QTY_PRICE_CART";
export const UPDATE_APPQUE_FR_PRICE_CART = "UPDATE_APPQUE_FR_PRICE_CART";
export const UPDATE_APPQUE_BK_PRICE_CART = "UPDATE_APPQUE_BK_PRICE_CART";
export const UPDATE_EMBDRY_FR_PRICE_CART = "UPDATE_EMBDRY_FR_PRICE_CART";
export const UPDATE_EMBDRY_BK_PRICE_CART = "UPDATE_EMBDRY_BK_PRICE_CART";
export const UPDATE_EMBDRY_LF_PRICE_CART = "UPDATE_EMBDRY_LF_PRICE_CART";
export const UPDATE_EMBDRY_RT_PRICE_CART = "UPDATE_EMBDRY_RT_PRICE_CART";
export const UPDATE_EMBDRY_LF_CUFF_PRICE_CART = "UPDATE_EMBDRY_LF_CUFF_PRICE_CART";
export const UPDATE_EMBDRY_RT_CUFF_PRICE_CART = "UPDATE_EMBDRY_RT_CUFF_PRICE_CART";
export const UPDATE_EMBDRY_COLLAR_PRICE_CART = "UPDATE_EMBDRY_COLLAR_PRICE_CART";
export const UPDATE_EMBDRY_COLLAR_FLIPPED_UP_PRICE_CART = "UPDATE_EMBDRY_COLLAR_FLIPPED_UP_PRICE_CART";
export const UPDATE_EMBDRY_HOOD_PRICE_CART = "UPDATE_EMBDRY_HOOD_PRICE_CART";
export const UPDATE_EMBDRY_HOOD_FORWARD_PRICE_CART = "UPDATE_EMBDRY_HOOD_FORWARD_PRICE_CART";
export const UPDATE_EMBDRY_HOOD_BACKWARD_PRICE_CART = "UPDATE_EMBDRY_HOOD_BACKWARD_PRICE_CART";
export const ADD_SPECIAL_INSTRUCTION_TO_CART = "ADD_SPECIAL_INSTRUCTION_TO_CART";

export function updateProductIDToCart(data: any) {
  return {
    type: UPDATE_PRODUCT_ID_TO_CART,
    data,
  };
}

export function addPriceToCart(data: any) {
  return {
    type: ADD_PRICE_CART,
    data,
  };
}

export function updatePriceToCart(data: any) {
  return {
    type: UPDATE_PRICE_CART,
    data,
  };
}

export function removePriceToCart(data: any) {
  return {
    type: REMOVE_PRICE_CART,
    data,
  };
}

export function addColorToCart(data: any) {
  return {
    type: ADD_COLOR_TO_CART,
    data,
  };
}

export function updateQuantityToCart(data: any) {
  return {
    type: UPDATE_QUANTITY_TO_CART,
    data,
  };
}

export function updateAppliqueToCart(data: any) {
  return {
    type: UPDATE_APPLIQUE_TO_CART,
    data,
  };
}

export function updateEmbroidaryToCart(data: any) {
  return {
    type: UPDATE_EMBROIDART_TO_CART,
    data,
  };
}

export function updatePreviewImagesToCart(data: any) {
  return {
    type: UPDATE_PREVIEW_IMAGES_TO_CART,
    data,
  };
}

export function updateSizeQtyPriceToCart(data: any) {
  return {
    type: UPDATE_SIZE_QTY_PRICE_CART,
    data,
  };
}

export function updateAppqeFrontPriceToCart(data: any) {
  return {
    type: UPDATE_APPQUE_FR_PRICE_CART,
    data,
  };
}

export function updateAppqeBackPriceToCart(data: any) {
  return {
    type: UPDATE_APPQUE_BK_PRICE_CART,
    data,
  };
}

export function updateEmbdryFrontPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_FR_PRICE_CART,
    data,
  };
}

export function updateEmbdryBackPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_BK_PRICE_CART,
    data,
  };
}

export function updateEmbdryLeftPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_LF_PRICE_CART,
    data,
  };
}

export function updateEmbdryRightPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_RT_PRICE_CART,
    data,
  };
}

export function updateEmbdryLeftCuffPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_LF_CUFF_PRICE_CART,
    data,
  };
}

export function updateEmbdryRightCuffPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_RT_CUFF_PRICE_CART,
    data,
  };
}

export function updateEmbdryCollarPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_COLLAR_PRICE_CART,
    data,
  };
}

export function updateEmbdryCollarFlippedUpPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_COLLAR_FLIPPED_UP_PRICE_CART,
    data,
  };
}

export function updateEmbdryHoodPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_HOOD_PRICE_CART,
    data,
  };
}

export function updateEmbdryHoodForwardPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_HOOD_FORWARD_PRICE_CART,
    data,
  };
}

export function updateEmbdryHoodBackwardPriceToCart(data: any) {
  return {
    type: UPDATE_EMBDRY_HOOD_BACKWARD_PRICE_CART,
    data,
  };
}

export function addSpecialInstructionToCart(data: any) {
  return {
    type: ADD_SPECIAL_INSTRUCTION_TO_CART,
    data,
  };
}

