import React, { ReactNode } from "react";
import {
  AccordionCard,
  AccordionHeader,
  AccordionCollapse,
} from "./styled.component";

interface AccordionProps {
  handleToggle: (id: String, isExpanded: boolean) => void;
  active: string | null;
  isExpanded: boolean;
  id: string;
  question: string;
  children: ReactNode;
}

interface AccordionState {}
export class Accordion extends React.Component<AccordionProps, AccordionState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AccordionCard>
        <AccordionHeader
          active={!this.props.isExpanded && this.props.active === this.props.id}
          onClick={() =>
            this.props.handleToggle(this.props.id, !this.props.isExpanded)
          }
        >
          <h5 className="rc-accordion-header">{this.props.question}</h5>
        </AccordionHeader>

        <AccordionCollapse
          collapse={
            !this.props.isExpanded && this.props.active === this.props.id
          }
        >
          <div className="rc-accordion-body">{this.props.children}</div>
        </AccordionCollapse>
      </AccordionCard>
    );
  }
}

export default Accordion;
