import { AnyAction } from "redux";

export const INCREMENT_LOADER = "INCREMENT_LOADER";
export const DECREMENT_LOADER = "DECREMENT_LOADER";

export function incrementLoader(): AnyAction {
  return {
    type: INCREMENT_LOADER,
  };
}

export function decrementLoader() {
  return {
    type: DECREMENT_LOADER,
  };
}
