import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import {
  getProfile,
  updateProfile,
  getProfileBillingAddresses,
  getProfileShippingAddresses,
  getShippingRates,
} from "../actions";
import {
  ProfileResponse,
  IProfileRequestParams,
  IAddresRequestParams,
  ProfileAddressResponse,
} from "../models";

class ProfileClient extends BaseApiClient {
  public async getProfile(dispatch: Dispatch) {
    try {
      const profileData = await this.get<ProfileResponse>("profile/get");
      dispatch(getProfile(profileData));
    } catch (error) {
      console.log(error);
    }
  }

  public async updateProfile(data: any, dispatch: Dispatch) {
    try {
      const profileData = await this.putFormData<ProfileResponse>(
        "user/update",
        data
      );
      //console.log("after update profileData : ", profileData);
      dispatch(updateProfile(profileData));
      return profileData;
    } catch (error) {
      console.log(error);
    }
  }

  generateFormData(data: any) {
    let formData = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }

  public async getProfileAddresses(
    dispatch: Dispatch,
    userId: string,
    addressType: string
  ) {
    try {
      //let addressListUrl = `shippingaddress/get?userId=${userId}&addressType=${addressType}`;
      let addressListUrl = `shippingaddress/get?userId=${userId}`;

      const profileAddressData = await this.get<ProfileAddressResponse>(
        addressListUrl
      );
      /*if (addressType == "billing") {
        dispatch(getProfileBillingAddresses(profileAddressData));
      } else {
        dispatch(getProfileShippingAddresses(profileAddressData));
      }*/
      dispatch(getProfileBillingAddresses(profileAddressData));
    } catch (error) {
      console.log(error);
    }
  }

  public async addAddress(data: IAddresRequestParams, dispatch: Dispatch) {
    try {
      const addressData = await this.postFormData<ProfileResponse>(
        "shippingaddress/add",
        this.generateFormData(data)
      );
      console.log("after update profileData : ", addressData);
      //dispatch(updateProfile(profileData));
      return addressData;
    } catch (error: any) {
      console.log("error : ", error.metadata);
      error = JSON.parse(error.metadata.apiErrorMessage);
      console.log("error : ", error);
      return error;
    }
  }

  public async updateAddress(data: IAddresRequestParams, dispatch: Dispatch) {
    try {
      const addressData = await this.putFormData<ProfileResponse>(
        "shippingaddress/update",
        this.generateFormData(data)
      );
      console.log("after update addressData : ", addressData);
      //dispatch(updateProfile(profileData));
      return addressData;
    } catch (error: any) {
      console.log("error : ", error.metadata);
      error = JSON.parse(error.metadata.apiErrorMessage);
      return error;
    }
  }

  public async deleteAddress(data: IAddresRequestParams, dispatch: Dispatch) {
    try {
      const addressData = await this.deleteFormData<ProfileResponse>(
        "shippingaddress/delete",
        this.generateFormData({ _id: data._id })
      );
      console.log("after update addressData : ", addressData);
      //dispatch(updateProfile(profileData));
      return addressData;
    } catch (error: any) {
      console.log("error : ", error.metadata);
      error = JSON.parse(error.metadata.apiErrorMessage);
      return error;
    }
  }

  public async getShippingRates(dispatch: Dispatch, orderValue: any) {
    try {
      const shippingRatesData = await this.get<ProfileResponse>(
        `shippingrates/get?orderValue=${orderValue}`
      );
      dispatch(getShippingRates(shippingRatesData));
      return shippingRatesData;
    } catch (error: any) {
      console.log(error);
      error = JSON.parse(error.metadata.apiErrorMessage);
      return error;
    }
  }
}

export const profileClient = new ProfileClient();
