import React from "react";
import { Title } from "../../components/Title";
import { Container, FormContainer } from "./styled.component";
import { FundraiseForm } from "./Funraise.form";

export class FundraisePage extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Title>Fundraises</Title>
        <Container>
          <p>
            A special fundraiser discount code will be given to your
            organization. If you refer others to purchase item(s) using this
            special fundraiser discount code, they will be given a 5% discount
            off the regular advertised purchased price. Your organization will
            then be given a 5% commission of that purchase. This fundraising
            promotion runs for 3 months, per school year.
          </p>
          <p>
            <b>Note:</b> This fundraiser is not valid for promotional discount
            pricing, discounted group orders and rush orders. The fundraiser
            discount code will have an expiration date noted. The commission
            check will be paid at the end of the promotion, with a minimum of
            $25.00 earned.
          </p>
          <FormContainer>
            <FundraiseForm />
          </FormContainer>
        </Container>
      </>
    );
  }
}
